import React, { useState, useEffect } from "react";
import ConnectorCanvasComponent from "./ConnectorCanvasComponent";
import { _deref } from "../utils/json";
import Icons from "../ui_component_library/Icons";
import { get_sku_details_core_format, get_sku_display_url } from "../utils/sku";
import IButton from "../ui_component_library/IButton";
import { parse_expression_interface, validate_expression_interface } from "../utils/expression";
import { Tooltip } from "antd";
import { useAlert } from "react-alert";


export const all_connector_types = [
    {
        id: "dowel_hinge",
        name: "Dowel/Hinge",
    },
    {
        id: "single_panel_connector",
        name: "Single Panel Connector",
    },
    {
        id: "cabinet_connector",
        name: "Cabinet Connector",
    }
]

const ModifyConnector = ({ mode, connector_type, curr_applicator, set_curr_applicator, sku_selection_onclick, selected_scenarios, scenarios_to_collisions_map }) => {

    const alert = useAlert();

    const [ sku_details, set_sku_details ] = useState({});

    const available_application_methods = [
        { id: "free", name: "Free placement" },
        { id: "equal_distance", name: "Equal Placement" },
        { id: "center_even", name: "Center Even" },
        { id: "center_odd", name: "Center Odd" }
    ];

    const [panel_width, set_panel_width] = useState(500);
    const [panel_depth, set_panel_depth] = useState(500);
    const [panel_thickness, set_panel_thickness] = useState(30);

    useEffect(() => {
        if(mode == "add" && connector_type){
            update_property("applicator_type", connector_type);
        }
    }, []);

    const get_collisions_at_ranges = () => {
        if(!scenarios_to_collisions_map || !selected_scenarios || !selected_scenarios.length || !curr_applicator || !(curr_applicator.placement_styles)){
            return [];
        }

        var placement_style_index_to_collisions_map = {};
        for(var i=0;i<selected_scenarios.length;i++){
            if(curr_applicator.placement_styles.length == 0){
                continue;
            }

            var scenario = selected_scenarios[i];
            var scenario_length = scenario.total_length;
            var placement_style_index = -1;

            if(scenario_length == undefined || scenario_length == ""){
                continue;
            }

            if(curr_applicator.placement_styles.length == 1){
                placement_style_index = 0;
            }else{
                for(var j=curr_applicator.placement_styles.length-1;j>=0;j--){
                    if(curr_applicator.placement_styles[j][0] <= scenario_length){
                        placement_style_index = j;
                        break;
                    }
                }
            }

            if(placement_style_index != -1 && scenarios_to_collisions_map[scenario.id]){
                if(!(placement_style_index_to_collisions_map[placement_style_index])){
                    placement_style_index_to_collisions_map[placement_style_index] = [];
                }

                placement_style_index_to_collisions_map[placement_style_index] = placement_style_index_to_collisions_map[placement_style_index].concat(scenarios_to_collisions_map[scenario.id]);
            }
        }

        return placement_style_index_to_collisions_map;
    }

    const get_collision_at_range_tooltip = (idx) => {
        var collisions = get_collisions_at_ranges()[idx];
        //for each collision show Panel Type, Panel Annotation, Scenario Length
        //get the scenario from selected_scenarios (collision.scenario is the id of the scenario)
        //for attached panels use this array scenario.attached_panels. eg: scenario.attached_panels[0].unique_label , scenario.attached_panels[0].panel_type

        return (
            <div style={{display:"flex",flexFlow:"column"}}>
                <div>
                    Collisions found at 
                </div>
                <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    margin: '1rem 0'
                }}>
                    <thead>
                        <tr>
                            <th style={{padding: '0.5rem',border: '1px solid #ddd'}}>Panel Annotation</th>
                            <th style={{padding: '0.5rem',border: '1px solid #ddd'}}>Panel Type</th>
                            <th style={{padding: '0.5rem',border: '1px solid #ddd'}}>Length</th>
                        </tr>
                    </thead>
                    <tbody>
                        {collisions.map((collision) => {
                            const scenario = selected_scenarios.find((s) => s.id == collision.scenario);
                            const colliding_panel_id = collision.panel;
                            const colliding_panel = scenario.attached_panels.find(panel => panel.id == colliding_panel_id)
                            if (scenario) {
                                return (
                                    <tr key={scenario.id}>
                                        <td style={{padding: '0.5rem',border: '1px solid #ddd'}}>
                                            {colliding_panel?colliding_panel.unique_label:"-"}
                                        </td>
                                        <td style={{padding: '0.5rem',border: '1px solid #ddd'}}>
                                            {colliding_panel?colliding_panel.panel_type:"-"}
                                        </td>
                                        <td style={{padding: '0.5rem',border: '1px solid #ddd'}}>
                                            {scenario.total_length}
                                        </td>
                                    </tr>
                                );
                            }
                            return null;
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    const get_filtered_offsets = (from_value, from_value1, placement_style_idx, applicator_to_use) => {
        //returns array of numbers if only 1D is passed. otherwise returns points
        if(!applicator_to_use){
            applicator_to_use = curr_applicator;
        }
        var result = [];
        
        //for hinges
		var alternate_from_values = {
			"front": "top",
			"back": "bottom"
		}

        for (var i = 0; i < applicator_to_use.placement_styles[placement_style_idx][1].offsets.length; i++) {
            var condition = (!from_value) || (from_value && applicator_to_use.placement_styles[placement_style_idx][1].offsets[i].from == from_value) || (!from_value1 && from_value && applicator_to_use.placement_styles[placement_style_idx][1].offsets[i].from == alternate_from_values[from_value]);
            var condition1 = (!from_value1) || (from_value1 && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1 == from_value1);
            if (condition && condition1) {
                if (from_value && from_value1) {
                    result.push({
                        x: applicator_to_use.placement_styles[placement_style_idx][1].offsets[i].distance1,
                        y: applicator_to_use.placement_styles[placement_style_idx][1].offsets[i].distance
                    })
                } else {
                    result.push(applicator_to_use.placement_styles[placement_style_idx][1].offsets[i].distance)
                }
            }
        }

        return result;
    }

    const parse_offset_value = (val) => {
        var params = { panel_width, panel_depth, panel_thickness, total_length: panel_width };

        var new_val = 0;
        if (!isNaN(Number(val))) {
            new_val = Number(val);
        } else if (val) {
            var is_valid = (validate_expression_interface(String(val), params) == "success");
            if (is_valid) {
                new_val = parse_expression_interface(String(val), params);
            }
        }

        return new_val;
    }

    const sort_offsets = (offsets) => {
        offsets.sort((a, b) => {
            if ((a.from == "front" || a.from == "top") && (b.from == "back" || b.from == "bottom")) {
                return -1;
            } else if ((a.from == "back" || a.from == "bottom") && (b.from == "front" || b.from == "top")) {
                return 1;
            } else if (a.from1 == "left" && b.from1 == "right") {
                return -1;
            } else if (a.from1 == "right" && b.from1 == "left") {
                return 1;
            } else {
                return parse_offset_value(a.distance) - parse_offset_value(b.distance);
            }
        });
    }

    const add_or_remove_connector = (mode, from, placement_style_idx) => {
        var applicator_clone = _deref(curr_applicator);
        var from1;

        if (from.includes("_")) {
            var split = from.split("_");
            from = split[0];
            from1 = split[1];
        }

        var remove_index = -1;
        var connector_before_sort;
        if (from == "index" && !isNaN(Number(from1))) {
            remove_index = Number(from1);
            connector_before_sort = applicator_clone.placement_styles[placement_style_idx][1].offsets[remove_index];
        }

        sort_offsets(applicator_clone.placement_styles[placement_style_idx][1].offsets);

        if (connector_before_sort) {
            remove_index = window._.findIndex(applicator_clone.placement_styles[placement_style_idx][1].offsets, (o) => o == connector_before_sort);
        }

        var filtered_offsets = get_filtered_offsets(from, from1, placement_style_idx, applicator_clone);

        if (mode == "add") {
            var new_connector = { from: from };
            if (from1) {
                var last_offset_x = filtered_offsets.length > 0 ? (parse_offset_value(filtered_offsets[filtered_offsets.length - 1].x)) : 0;
                var last_offset_y = filtered_offsets.length > 0 ? (parse_offset_value(filtered_offsets[filtered_offsets.length - 1].y)) : 0;
                new_connector.from1 = from1;
                new_connector.distance1 = last_offset_x + 50; new_connector.distance1 = String(new_connector.distance1);
                new_connector.distance = last_offset_y + 50; new_connector.distance = String(new_connector.distance);
                new_connector.panel_face = "bottom";
            } else {
                var last_offset = filtered_offsets.length > 0 ? (parse_offset_value(filtered_offsets[filtered_offsets.length - 1])) : 0;
                if(from == "center"){
                    last_offset = Math.max(last_offset, 0);
                }
                new_connector.distance = last_offset + 50; new_connector.distance = String(new_connector.distance);
            }
            applicator_clone.placement_styles[placement_style_idx][1].offsets.push(new_connector);
        } else if (mode == "remove") {
            if (remove_index == -1) {
                //for hinges
                var alternate_from_values = {
                    "front": "top",
                    "back": "bottom"
                }

                for (var i = 0; i < applicator_clone.placement_styles[placement_style_idx][1].offsets.length; i++) {
                    var condition = (!from) || (from && applicator_clone.placement_styles[placement_style_idx][1].offsets[i].from == from) || (!from1 && from && applicator_clone.placement_styles[placement_style_idx][1].offsets[i].from == alternate_from_values[from]);;
                    var condition1 = (!from1) || (from1 && applicator_clone.placement_styles[placement_style_idx][1].offsets[i].from1 == from1);
                    var condition2 = applicator_clone.placement_styles[placement_style_idx][1].offsets[i].from == "not_set" || applicator_clone.placement_styles[placement_style_idx][1].offsets[i].from == "";

                    var condition3 = applicator_clone.application_method == "equal_distance" && applicator_clone.applicator_type == "dowel_hinge"; //can remove any index, doesnt matter

                    if (condition2 || (condition && condition1) || condition3) {
                        remove_index = i;
                    }
                }
            }

            if (remove_index != -1) {
                window._.pullAt(applicator_clone.placement_styles[placement_style_idx][1].offsets, remove_index);
            }
        }

        set_curr_applicator(applicator_clone);
    }

    const convert_non_fixed_to_fixed_applicator = (curr_applicator, applicator_clone, old_application_method) => {
        for(var p=0;p<applicator_clone.placement_styles.length;p++){
            var idx = p;
            
            var front_positions = [];
            if(old_application_method == "equal_distance"){

                var available_length = "total_length - " + curr_applicator.placement_styles[idx][1].min_start_distance + " - " + curr_applicator.placement_styles[idx][1].min_end_distance;
                var number_of_offsets = curr_applicator.placement_styles[idx][1].offsets.length;
                if(number_of_offsets == 1){
                    front_positions = ["total_length/2"];
                }else if(number_of_offsets > 1){
                    front_positions = [String(curr_applicator.placement_styles[idx][1].min_start_distance)];
                    var middle_offsets_to_add = number_of_offsets - 2;
                    var gap_diff = "(" + available_length + ")/" + String(middle_offsets_to_add + 1);

                    for(var n=0;n<middle_offsets_to_add;n++){
                        front_positions.push(curr_applicator.placement_styles[idx][1].min_start_distance + "+" + String(n+1) + "*" + gap_diff );
                    }

                    front_positions.push("total_length - " + String(curr_applicator.placement_styles[idx][1].min_end_distance));
                }
                
            }else if(old_application_method.includes("center_")){
                let curr_panel_width = curr_applicator.placement_styles[idx][1].assumed_panel_width || panel_width;
                let half_length = curr_panel_width/2.0;
                let length_used = 0;

				if(old_application_method == "center_odd"){
					front_positions.push(half_length);
				}
				
				var min_start_distance = parse_offset_value(curr_applicator.placement_styles[idx][1].min_start_distance);
				var min_end_distance = parse_offset_value(curr_applicator.placement_styles[idx][1].min_end_distance);;

				var repeat_distance = parse_offset_value(curr_applicator.placement_styles[idx][1].repeat_distance);
				
                while((length_used + repeat_distance) < (half_length - min_start_distance)){
                    front_positions.push(half_length - length_used - repeat_distance);
					
                    length_used += repeat_distance;
                }
				
				length_used = 0;
				while((length_used + repeat_distance) < (half_length - min_end_distance)){
					front_positions.push(half_length + length_used + repeat_distance);
                    length_used += repeat_distance;
                }
            }

            applicator_clone.placement_styles[idx][1].offsets = [];
            for (var i = 0; i < front_positions.length; i++) {
                applicator_clone.placement_styles[idx][1].offsets.push({ from: "front", distance: front_positions[i] });
            }
        }
    }

    const update_property = (property, val, placement_style_idx) => {
        var applicator_clone = _deref(curr_applicator);
        if(property == "applicator_type"){
            applicator_clone.applicator_type = val;
        }
        if (property == "repeat_distance" || property == "min_start_distance" || property == "min_end_distance") {
            applicator_clone.placement_styles[placement_style_idx][1][property] = val;
        } else if (property == "name") {
            applicator_clone.name = val;
        } else if (property == "application_method") {
            var old_application_method = applicator_clone.application_method;
            var new_application_method = val;
            if(old_application_method == "equal_distance" && new_application_method == "free"){
                convert_non_fixed_to_fixed_applicator(curr_applicator, applicator_clone, old_application_method);
            }
            applicator_clone.application_method = val;

        } else if(property == "assumed_panel_width"){
            if(isNaN(Number(val)) || !val){
                alert.error("Invalid value for assumed panel width");
                return;
            }
            applicator_clone.placement_styles[placement_style_idx][1].assumed_panel_width = Number(val);
        }
        // else if(property == "assumed_panel_thickness"){
        //     if(isNaN(Number(val)) || !val){
        //         alert.error("Invalid value for assumed panel thickness");
        //         return;
        //     }
        //     applicator_clone.assumed_panel_thickness = val;
        // }
        else if(property == "assumed_panel_depth"){
            if(isNaN(Number(val)) || !val){
                alert.error("Invalid value for assumed panel depth");
                return;
            }
            applicator_clone.placement_styles[placement_style_idx][1].assumed_panel_depth = Number(val);
        }

        set_curr_applicator(applicator_clone);
    }

    const update_offsets = ({ idx, from, from1, curr_from, val, placement_style_idx }) => {
        var params = { panel_width, panel_depth, panel_thickness, total_length: panel_width };

        val = String(val);

        if (isNaN(Number(val)) && (validate_expression_interface(val, params) == "failure")) {
            return;
        }


        var applicator_clone = _deref(curr_applicator);

        var curr_index = 0;
        
        var alternate_from_values = {
            "front": "top",
            "back": "bottom"
        }

        for (var i = 0; i < applicator_clone.placement_styles[placement_style_idx][1].offsets.length; i++) {
            var condition = (!from) || (from && applicator_clone.placement_styles[placement_style_idx][1].offsets[i].from == from) || (!from1 && from && applicator_clone.placement_styles[placement_style_idx][1].offsets[i].from == alternate_from_values[from]);
            var condition1 = (!from1) || (from1 && applicator_clone.placement_styles[placement_style_idx][1].offsets[i].from1 == from1);

            if (condition && condition1 && curr_index == idx) {
                if (curr_from == from) {
                    applicator_clone.placement_styles[placement_style_idx][1].offsets[i].distance = val;
                } else if (curr_from == from1) {
                    applicator_clone.placement_styles[placement_style_idx][1].offsets[i].distance1 = val;
                }
            }

            if (condition && condition1) {
                curr_index += 1;
            }
        }
        set_curr_applicator(applicator_clone);
    }

    const canvas_ui_action_callback = (activeElement) => {
        if (activeElement.object == "repeat_distance" || activeElement.object == "min_start_distance" || activeElement.object == "min_end_distance") {
            update_property(activeElement.object, activeElement.val,activeElement.placement_style_idx);
        }else if (activeElement.object == "offset") {
            update_offsets(activeElement);
        }else if (activeElement.object.includes("add_button_") || activeElement.object.includes("remove_button_")) {
            var split = activeElement.object.split("_button_");
            add_or_remove_connector(split[0], split[1],activeElement.placement_style_idx);
        }else if(activeElement.object.includes("switch_face_")){
            var split = activeElement.object.split("switch_face_");
            var index = split[1];
            var applicator_clone = _deref(curr_applicator);
            applicator_clone.offsets[index].panel_face = applicator_clone.offsets[index].panel_face == "top" ? "bottom" : "top";
            set_curr_applicator(applicator_clone,activeElement.placement_style_idx);
        }
    }

    const handleKeyUp = (event, defaultValue) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
			event.target.blur()
		}else if(event.key === 'Escape'){
			// Reset the value
            event.target.value = defaultValue;
			event.target.blur()
		}
    }

    useEffect(() => {
        if(curr_applicator.sku_id){
            if((curr_applicator.sku_id != sku_details.id)){
                (async() => {
                    let _sku_details = await (get_sku_details_core_format(curr_applicator.sku_id))
                    set_sku_details(_sku_details)
                })()
            }
        }else{
            set_sku_details({})
        }
    }, [curr_applicator]);


    const addNewRange = () => {
        var new_range_min_position = 0;
        for(var i=0;i<curr_applicator.placement_styles.length;i++){
            if(curr_applicator.placement_styles[i][0] > new_range_min_position){
                new_range_min_position = curr_applicator.placement_styles[i][0];
            }
        }
        new_range_min_position += 500;

        var applicator_clone = _deref(curr_applicator);
        applicator_clone.placement_styles.push([new_range_min_position, {
            repeat_distance: "50",
            offsets: [],
            min_start_distance: "100",
            min_end_distance: "100",
            assumed_panel_depth: 500,
            assumed_panel_width: 500,
            assumed_panel_thickness: 30
        }]);
        set_curr_applicator(applicator_clone);
    }

    const removeRange = (idx) => {
        var applicator_clone = _deref(curr_applicator);
        applicator_clone.placement_styles.splice(idx, 1);
        set_curr_applicator(applicator_clone);
    }

    const changeRangeValue = (idx, val) => {
        var applicator_clone = _deref(curr_applicator);
        applicator_clone.placement_styles[idx][0] = val;
        set_curr_applicator(applicator_clone);
    }

    return (
        <div className='flex_column inf-gap-4'>
            <div className="inf-flex inf-flex-col inf-gap-1">
                <div style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>Connector Name</div>
                <input className="cam_applicator_input" value={curr_applicator.name} onChange={(e) => update_property("name", e.target.value)} type="text" />
            </div>
            
            {
                // mode == "add" && !connector_type ?
                <div className="inf-flex inf-flex-col inf-gap-1">
                    <div style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>Applicable Scenario Type</div>
                    <select disabled={mode !== "add"} className="cam_applicator_input" type='select' value={curr_applicator.applicator_type} onChange={(e) => update_property("applicator_type", e.target.value)}>
                        {all_connector_types.map(connector_type_option => (
                            <option value={connector_type_option.id}>{connector_type_option.name}</option>
                        ))}
                    </select>
                </div>
                // : ''
            }

            <div className="inf-flex inf-flex-col inf-gap-1">
                <div style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>Connector Sku</div>
                {
                    curr_applicator.sku_id == "" ?
                        <div className="flex_center inf-gap-2 inf-p-6 inf-border cp rounded " onClick={sku_selection_onclick}>
                            <Icons name={"add"} />
                            <div>Select SKU</div>
                        </div>
                        :
                        <div className='flex_between inf-border inf-p-2 rounded'>
                            <div className='flex_property inf-gap-2'>
                                <img style={{ height: '64px' }} src={get_sku_display_url(sku_details && sku_details.display_pic)} />
                                {sku_details && sku_details.sku_name || 'N/A'}
                            </div>
                            <IButton ghost={true} onClick={sku_selection_onclick}>Change</IButton>
                        </div>

                }
            </div>
            
            <div className='flex_column inf-p-4 inf-gap-4' style={{ backgroundColor: "#EAECF0", borderRadius: '8px' }}>
                    {
                        curr_applicator.applicator_type == "dowel_hinge" ?
                            <div className="inf-flex inf-flex-col inf-gap-1">
                                <div style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>Placement Setting</div>
                                <div className="flex_property">
                                    <select className="cam_applicator_input" style={{width:"100%"}} type='select' value={curr_applicator.application_method} onChange={(e) => update_property("application_method", e.target.value)}>
                                        {available_application_methods.map(o => (
                                            <option value={o.id}>{o.name}</option>
                                        ))}
                                    </select>
                                    {curr_applicator.application_method.includes("center")?(
                                        <Tooltip trigger={['click']} placement="bottomRight" overlayStyle={{width: '400px', maxWidth: 'unset'}} overlayInnerStyle={{maxHeight: '500px', overflow: 'auto'}} title={
                                            <div className="flex_column inf-gap-2">
                                                Required parameters:   Repeat distance (starting from center), Minimum distance from start, Minimum Distance from End
                                            </div>
                                            }>
                                            <Icons className={'inf-p-1'} onClick={(e) => e.stopPropagation()} name={'info'}></Icons>
                                        </Tooltip>
                                    ):''}
                                </div>
                            </div>
                            : ''
                    }
                <div style={{ position: "relative" }}>
                    <div className="flex_property inf-gap-4">
                        <IButton onClick={addNewRange}>+ Add Range</IButton>
                        <div style={{fontWeight: 'bold'}}>Currently added number of ranges: ({curr_applicator && curr_applicator.placement_styles && curr_applicator.placement_styles.length || 0}) </div>
                        <div className="flex_property inf-gap-2">
                            <div>Tolerance: </div>
                           <b>0.1mm</b>
                        </div>
                    </div>
                    {curr_applicator && curr_applicator.placement_styles && curr_applicator.placement_styles.map((placement_style, idx) => 
                        <div className="flex_between">
                            <div className="flex_column inf-gap-3 inf-p-3 inf-border rounded-xl" style={{backgroundColor: "#FFFFFF"}}>
                                <b>Range {Number(idx) + 1}</b>
                                <div className="flex_property inf-gap-2">
                                    <div className="flex_property inf-gap-4">
                                        <div>From</div>
                                        <input size={8} style={{width: '120px'}} className="cam_applicator_input" disabled={idx==0}  key={"from_"+idx+"_" + placement_style[0]} defaultValue={placement_style[0]} onKeyUp={(e) => { if(e.key === "Enter") changeRangeValue(idx, Number(e.target.value)); }} onBlur={(e) => changeRangeValue(idx, Number(e.target.value))} type="number" />
                                    </div>
                                    {idx !== 0 ? "+ Tolerance" : ""}
                                    {
                                        idx === (curr_applicator.placement_styles.length - 1) && idx !== 0 ? 
                                        <Tooltip title={
                                            "The value in the To field for the previous range and the Value in the From field for the next range will always match. Tolerance is added to the To field value so that if the scenario length is 499.99 or 500.01 and the To field reads 500 - then both these scenario lengths should be covered in the same range"
                                        }>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.3" cx="8" cy="8" r="8" fill="#C5C7CF" />
                                                <path d="M8.97731 3.19922C9.55992 3.19922 9.85123 3.6005 9.85123 4.0603C9.85123 4.6345 9.34514 5.16558 8.68644 5.16558C8.1347 5.16558 7.81296 4.83558 7.82818 4.28998C7.82818 3.83106 8.21123 3.19922 8.97731 3.19922ZM7.1847 11.9992C6.7247 11.9992 6.38775 11.7123 6.70949 10.4487L7.23731 8.20818C7.32905 7.85002 7.34427 7.70614 7.23731 7.70614C7.09949 7.70614 6.50297 7.95342 6.14949 8.19762L5.91992 7.81042C7.03818 6.84858 8.3247 6.28494 8.87688 6.28494C9.33644 6.28494 9.41297 6.84506 9.1834 7.70614L8.57862 10.061C8.47166 10.4768 8.51731 10.6203 8.6247 10.6203C8.76253 10.6203 9.2147 10.4478 9.65905 10.0892L9.91992 10.4473C8.8321 11.568 7.64383 11.9992 7.1847 11.9992Z" fill="#676878" />
                                            </svg>
                                        </Tooltip>
                                        : ''
                                    }
                                    <div className="flex_property inf-gap-4">
                                        <div>To</div>
                                        {idx == curr_applicator.placement_styles.length-1?(
                                            <input size={8} style={{width: '120px'}} key={idx+"_1"} className="cam_applicator_input" defaultValue={"Infinity"} disabled={true} type="text" />
                                        ):(
                                            <input size={8} style={{width: '120px'}} key={idx+"_2_"+curr_applicator.placement_styles[idx+1][0]} className="cam_applicator_input" disabled={true} defaultValue={curr_applicator.placement_styles[idx+1][0]} type="number" />
                                        )}
                                    </div>
                                    {
                                        idx !== (curr_applicator.placement_styles.length - 1) ? 
                                            <>
                                            + Tolerance
                                            <Tooltip title={
                                                "The value in the To field for the previous range and the Value in the From field for the next range will always match. Tolerance is added to the To field value so that if the scenario length is 499.99 or 500.01 and the To field reads 500 - then both these scenario lengths should be covered in the same range"
                                            }>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle opacity="0.3" cx="8" cy="8" r="8" fill="#C5C7CF" />
                                                    <path d="M8.97731 3.19922C9.55992 3.19922 9.85123 3.6005 9.85123 4.0603C9.85123 4.6345 9.34514 5.16558 8.68644 5.16558C8.1347 5.16558 7.81296 4.83558 7.82818 4.28998C7.82818 3.83106 8.21123 3.19922 8.97731 3.19922ZM7.1847 11.9992C6.7247 11.9992 6.38775 11.7123 6.70949 10.4487L7.23731 8.20818C7.32905 7.85002 7.34427 7.70614 7.23731 7.70614C7.09949 7.70614 6.50297 7.95342 6.14949 8.19762L5.91992 7.81042C7.03818 6.84858 8.3247 6.28494 8.87688 6.28494C9.33644 6.28494 9.41297 6.84506 9.1834 7.70614L8.57862 10.061C8.47166 10.4768 8.51731 10.6203 8.6247 10.6203C8.76253 10.6203 9.2147 10.4478 9.65905 10.0892L9.91992 10.4473C8.8321 11.568 7.64383 11.9992 7.1847 11.9992Z" fill="#676878" />
                                                </svg>
                                            </Tooltip>
                                            </>
                                        :''
                                    }
                                    {get_collisions_at_ranges()[idx] && get_collisions_at_ranges()[idx].length?(
                                        <Tooltip trigger={['click']} placement="bottomRight" overlayStyle={{ width: '400px', maxWidth: 'unset' }} overlayInnerStyle={{ maxHeight: '500px', overflow: 'auto' }} title={get_collision_at_range_tooltip(idx)}>
                                            <Icons className={'inf-p-1'} onClick={(e) => e.stopPropagation()} name={'warning'} style={{cursor: 'pointer', color: '#BF2600', fontSize: '16px'}}></Icons>
                                        </Tooltip>
                                    ):''}
                                </div>
                                {
                                    curr_applicator.applicator_type == "dowel_hinge" ?
                                        <div className="flex_property inf-gap-4">
                                            <div className="flex_property inf-gap-4">
                                                <div>Panel width assumed for diagram</div>
                                                <input className="cam_applicator_input" defaultValue={curr_applicator.assumed_panel_width || panel_width}  onKeyUp={(event) => handleKeyUp(event, curr_applicator.assumed_panel_width || panel_width)} onBlur={(e) => update_property("assumed_panel_width", e.target.value, idx)} type="number" />
                                            </div>
                                        </div>
                                    : ''
                                }
                                <div className="flex_between">
                                    {"Qty: " + (curr_applicator.application_method.includes("center_")?"Dynamic":curr_applicator.placement_styles[idx][1].offsets.length)}
                                    {idx!=0?(<IButton ghost={true} color={'warning'} onClick={() => {removeRange(idx)}}><div className="flex_property inf-gap-2"><Icons className="cp" name={"remove"} /> Remove Range </div></IButton>):''}
                                </div>
                            </div>
                            <ConnectorCanvasComponent
                                curr_applicator={curr_applicator}
                                placement_style_idx={idx}
                                with_controller={true}
                                ui_action_callback={canvas_ui_action_callback}
                                canvas_width={curr_applicator.applicator_type == "dowel_hinge" ? 500 : 400} canvas_height={curr_applicator.applicator_type == "dowel_hinge" ? 200 : 400}
                                panel_width={placement_style[1].assumed_panel_width || panel_width} panel_depth={placement_style[1].assumed_panel_depth || panel_depth} panel_thickness={panel_thickness}
                            />
                        </div>
                    )}
                </div>
                {curr_applicator&&curr_applicator.applicator_type == "single_panel_connector" ? (<div className="flex_column inf-gap-3">
                    <div className="flex_between inf-gap-4">
                        <div className="flex_property inf-gap-4">
                            <div>Panel width assumed for diagram</div>
                            <input className="cam_applicator_input" defaultValue={curr_applicator.assumed_panel_width || panel_width}  onKeyUp={(event) => handleKeyUp(event, curr_applicator.assumed_panel_width || panel_width)} onBlur={(e) => update_property("assumed_panel_width", e.target.value,0)} type="number" />
                        </div>
                        <div className="flex_property inf-gap-4">
                            <div>Panel depth assumed for diagram</div>
                            <input className="cam_applicator_input" defaultValue={curr_applicator.assumed_panel_depth || panel_depth}  onKeyUp={(event) => handleKeyUp(event, curr_applicator.assumed_panel_depth || panel_depth)} onBlur={(e) => update_property("assumed_panel_depth", e.target.value,0)} type="number" />
                        </div>
                    </div>
                    <div style={{ display: "flex",flexFlow:"column", gap: "8px", alignItems: 'flex-end' }}>
                        <div style={{display:"grid",gridTemplateColumns: "1fr 2fr",gap:4,alignItems:"center"}}>
                            <div style={{ width: "100%", height: "2px", backgroundColor: "black" }}></div>
                            <div>Connectors on Top side</div>
                        </div>
                        <div style={{display:"grid",gridTemplateColumns: "1fr 2fr",gap:4,alignItems:"center"}}>
                        <div style={{ 
                            width: "100%", 
                            height: "2px", 
                            borderTop: "2px dashed black",
                            backgroundColor: "transparent"
                        }}></div>
                            <div>Connectors on Bottom side</div>
                        </div>
                    </div>
                </div>) : ''}
            </div>
        
        </div>
    )
}

export default ModifyConnector;