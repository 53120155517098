import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import DesignViewContext from "../../views/EditDesign/DesignViewContext";
import Icons from "../../shared_components/ui_component_library/Icons";
import NavBar_Dropdown from "../NavBar_Dropdown";
import { COLORS } from "../../utils/color";
import NavBar_Dropdown_New from "../NavBar_Dropdown_New";
import NavBar_Dropdown_Left from "../NavBar_Dropdown_Left";
import { useAlert } from "react-alert";
import NavBar_Dropdown_BF from "../Navbar_Dropdown_BF";
import sleep from "../../utils/sleep";
import logout from "../../utils/logout";
import { Tooltip } from "antd";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { toReadableDate } from "../../utils/time_utils";
import get_org_key from "../../utils/get_org_key";
import ITriggerModal from "../../shared_components/ui_component_library/ITriggerModal";
import { Account_Settings_Modal, Edit_Design_Modal, InfurniaPlansModal, View_Live_Codesigners_Modal } from "../CommonModals";
import { validate_design_name } from "../../utils/validate_name";
import './style.css';
import get_project_overridden_fields from '../../utils/get_admin_prefs.js';
import { use } from "react";

const PerspectiveSwitcher = ({ elevation_cameras, custom_plans_cameras }) => {
    const { perspective, seller_specific, mes_editor, update_view, handle_ui_response, interface_mode, submit_for_delete_confirm } = useContext(DesignViewContext)
    const alert = useAlert()

    const navbar_floorplan_click = () => {
        if (window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()) {
            if (window.debug_mode) {
                window.add_debug_log("navbar_floorplan_click();");
            }
            window.Module.navbar_floorplan_click();
            update_view();
        } else {
            alert.error("Unlock the view to switch modes");
        }
    }

    const navbar_3d_click = () => {
        if (window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()) {
            if (window.debug_mode) {
                window.add_debug_log("navbar_3d_click();");
            }
            window.Module.navbar_3d_click();
            update_view();
            window.three_interface.em_interface.needs_redraw = true;
        } else {
            alert.error("Unlock the view to switch modes");
        }
    }

    const navbar_elevation_click = (id) => {
        if (id == "add_elevation_view") {
            if (window.debug_mode) {
                window.add_debug_log("add_elevation_view_frontend(\"\");");
            }
            window.Module.add_elevation_view_frontend("");
            update_view();
        } else {
            if (window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()) {
                if (window.debug_mode) {
                    var cam_index = 0;
                    elevation_cameras.map((o, idx) => {
                        if (o.id == id) {
                            cam_index = idx;
                        }
                    });

                    window.add_debug_log("navbar_elevation_click(active_design->world->elevation_cameras[" + cam_index + "]->id);");
                }
                window.Module.navbar_elevation_click(id);
                update_view();
            } else {
                alert.error("Unlock the view to switch modes");
            }
        }
    }

    return (

        interface_mode == "design_edit_mode" ?
            <div className='perspective_switcher_container'>
                <div onClick={navbar_floorplan_click} className={`perspective_item ${perspective == 'floorplan' ? 'perspective_item_active' : ''}`} title="Floor Plan" > <Icons name={'floor_plan'} /> Floorplan</div>
                <div onClick={navbar_3d_click} className={`perspective_item ${perspective == '3d' ? 'perspective_item_active' : ''}`} title="3D View" > <Icons name={'room'} /> 3D</div>
                {
                    <NavBar_Dropdown
                        id="elevation_cameras_dropdown"
                        before_open={() => { handle_ui_response(JSON.stringify({ update_elevation_cameras: true, update_custom_plan_cameras: true })) }}
                        on_select={navbar_elevation_click}
                        elevation_options={elevation_cameras}
                        custom_plan_options={custom_plans_cameras}
                        display_name={'Elevation'}
                        className={''}
                        submit_for_delete_confirm={submit_for_delete_confirm}
                        update_view={update_view}
                        active={perspective === 'elevation'}
                        handle_ui_response={handle_ui_response}
                    />
                }
            </div>
            : ''

    )
}

const DesignBreadCrumbBar = ({ seller_sku_details, viewing_level, level_arrays, presentation_sheets, boq_schedules, board_layout_sheets, board_layout_sheet_types }) => {
    const { perspective, seller_specific, mes_editor, update_view, handle_ui_response, interface_mode, set_page_loader, toolbar_on_click } = useContext(DesignViewContext)

    const alert = useAlert();
    const switch_mode = (id) => {
        // if(window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()){
        if (window.debug_mode) {
            window.add_debug_log("toolbar_on_click(\"" + id + "\");");
        }
        toolbar_on_click(id)
        // }else{
        //     alert.error("Unlock the view to switch modes");
        // }
    }

    const switch_world = () => {
        if (interface_mode == "design_edit_mode") {
            if (window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()) {
                var active_design = window.Module.get_active_design();
                var active_view = active_design.active_view;
                if (window.debug_mode) {
                    window.add_debug_log("switchWorld()");
                }
                active_view.switchWorld();
                active_view.trigger_vr_news_based_on_level();
                update_view();
                active_design.delete();
                active_view.delete();
            } else {
                alert.error("Unlock the view to switch modes");
            }
        }
    }

    const switch_building = (id) => {
        if (window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()) {
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            if (window.debug_mode) {
                var building_index = 0;
                level_arrays.buildings.map((o, idx) => {
                    if (o.id == id) {
                        building_index = idx;
                    }
                });

                window.add_debug_log("switchBuilding(active_design->world->buildings[" + building_index + "]->id);");
            }
            active_view.switchBuilding(id);
            active_view.trigger_vr_news_based_on_level();
            update_view();
            active_design.delete();
            active_view.delete();
        } else {
            alert.error("Unlock the view to switch modes");
        }
    }

    const switch_floor = (id) => {
        if (window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()) {
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            if (window.debug_mode) {
                var floor_index = 0;
                level_arrays.floors.map((o, idx) => {
                    if (o.id == id) {
                        floor_index = idx;
                    }
                });

                window.add_debug_log("switchFloor(active_design->active_view->get_active_building()->floors[" + floor_index + "]->id);");
            }
            active_view.switchFloor(id);
            active_view.trigger_vr_news_based_on_level();
            update_view();
            handle_ui_response(JSON.stringify({ update_camera_positioning_value: true }))
            active_design.delete();
            active_view.delete();
        } else {
            alert.error("Unlock the view to switch modes");
        }
    }

    const switch_room = (id) => {
        if (window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()) {
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            if (window.debug_mode) {
                var room_index = 0;
                level_arrays.rooms.map((o, idx) => {
                    if (o.id == id) {
                        room_index = idx;
                    }
                });

                window.add_debug_log("switchRoom(active_design->active_view->get_active_floor()->rooms[" + room_index + "]->id);");
            }
            active_view.switchRoom(id);
            active_view.trigger_vr_news_based_on_level();
            update_view();
            active_design.delete();
            active_view.delete();
        } else {
            alert.error("Unlock the view to switch modes");
        }
    }

    const switch_presentation_sheet = async (id) => {
        if (window.debug_mode) {
            window.add_debug_log("switch_presentation_sheet(" + id + ");");
        }
        set_page_loader({ show: true, text: "Loading Presentation Sheet..." }, async () => {
            await sleep(10)
            window.Module.switch_presentation_sheet(Number(id));
            set_page_loader({ show: false, text: "" })
            window.two_interface.handleWindowResize()
            update_view();
        })
    }

    const switch_boq_schedule = (id) => {
        if (window.debug_mode) {
            window.add_debug_log("switch_schedule(" + id + ");");
        }
        window.Module.switch_schedule(Number(id));
        update_view();
    }

    const switch_board_layout_sheet = (id) => {
        if (window.debug_mode) {
            window.add_debug_log("switch_board_layout_sheet(" + id + ");");
        }
        window.Module.switch_board_layout_sheet(Number(id));
        update_view();
    }

    const switch_board_layout_sheet_type = (id) => {
        if (window.debug_mode) {
            window.add_debug_log("switch_board_layout_sheet_type(" + id + ");");
        }
        window.Module.switch_board_layout_sheet_type(Number(id));
        update_view();
    }


    return (
        <div id="infurnia-design-bread-crumb-bar">
            {!seller_specific && !mes_editor ?
                <NavBar_Dropdown_New
                    id="mode_dropdown"
                    before_open={() => { handle_ui_response(JSON.stringify({ update_level_arrays: true })) }}
                    on_select={switch_mode}
                    options={level_arrays.mode}
                    display_name={level_arrays.active_mode ? level_arrays.active_mode.name : 'Design'}
                    className="blue_hover  design_border_radius"
                />
                :
                <NavBar_Dropdown_New
                    id="mode_dropdown"
                    display_name={mes_editor ? 'MES Editor' : 'Component Editor'}
                    className="blue_hover "
                />
            }
            {mes_editor ? (<React.Fragment>
                <div className="design-bread-crumb-nav-item">
                    {seller_sku_details ? seller_sku_details.category_name : ''}
                </div>
                <div className="design-bread-crumb-nav-item">
                    {seller_sku_details ? seller_sku_details.sub_category_name : ''}
                </div>
                <div className="design-bread-crumb-nav-item">
                    {seller_sku_details ? seller_sku_details.group_name : ''}
                </div>
                <div className="design-bread-crumb-nav-item">
                    {seller_sku_details ? seller_sku_details.sku_name : ''}
                </div>
            </React.Fragment>) : ''}
            {seller_specific ? (<React.Fragment>
                <div className="design-bread-crumb-nav-item">
                    {seller_sku_details ? seller_sku_details.category_name : ''}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><i className="fa fa-circle" style={{ color: COLORS.gray4, margin: 'auto 5px', fontSize: '6px' }}></i></div>
                <div className="design-bread-crumb-nav-item">
                    {seller_sku_details ? seller_sku_details.sub_category_name : ''}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><i className="fa fa-circle" style={{ color: COLORS.gray4, margin: 'auto 5px', fontSize: '6px' }}></i></div>
                <div className="design-bread-crumb-nav-item">
                    {seller_sku_details ? seller_sku_details.group_name : ''}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><i className="fa fa-circle" style={{ color: COLORS.gray4, margin: 'auto 5px', fontSize: '6px' }}></i></div>
                <div className="design-bread-crumb-nav-item">
                    {seller_sku_details ? seller_sku_details.sku_name : ''}
                </div>
            </React.Fragment>) : ''}
            {interface_mode == "design_edit_mode" && !seller_specific && !mes_editor && (
                <React.Fragment>
                    <div style={{ padding: '8px 12px 8px 12px', height: '30px', borderTopRightRadius: '999px', borderBottomRightRadius: '999px'/* , background: 'linear-gradient(to right, transparent, #FFFFFF30)' */ }}>
                        <a className="blue_hover" onClick={switch_world} style={{ fontSize: '12px', padding: '0px', display: 'flex', gap: '8px', cursor: 'pointer' }}>
                            <Icons name='world' style={{ maxWidth: '30px', maxHeight: '20px', objectFit: 'contain', margin: 'auto 0', fontSize: '12px' }} />
                        </a>
                    </div>
                </React.Fragment>
            )}
            {interface_mode == "presentation" && !seller_specific && (
                <React.Fragment>
                    <NavBar_Dropdown_Left
                        id="sheets_dropdown"
                        before_open={() => { handle_ui_response(JSON.stringify({ update_level_arrays: true })) }} on_select={switch_presentation_sheet}
                        options={presentation_sheets.sheets}
                        display_name={presentation_sheets.active_sheet ? presentation_sheets.active_sheet.name : 'Select Page'}
                        className="blue_hover "
                    />
                </React.Fragment>
            )}
            {interface_mode == "boq_mode" && !seller_specific && (
                <React.Fragment>
                    <div>
                        <a className="blue_hover " style={{ fontSize: '12px', padding: '0px', display: 'flex' }}>
                            <span style={{ cursor: 'pointer' }}>Schedule</span>
                        </a>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><i className="fa fa-circle" style={{ color: COLORS.gray4, margin: 'auto 5px', fontSize: '6px' }}></i></div>
                    <NavBar_Dropdown_Left
                        id="schedules_dropdown"
                        on_select={switch_boq_schedule}
                        options={boq_schedules.schedules}
                        display_name={boq_schedules.active_schedule ? boq_schedules.active_schedule.name : 'Select Schedule'}
                        className="blue_hover "
                    />
                </React.Fragment>
            )}
            {/* {interface_mode == "board_layout_mode" && !seller_specific && (
                <React.Fragment>
                    <div style={{ padding: '8px', height: '30px' }}>
                        <a className="blue_hover " style={{ fontSize: '12px', padding: '0px', display: 'flex' }}>
                            <span style={{ cursor: 'pointer' }}>Board Layout</span>
                        </a>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><i className="fa fa-circle" style={{ color: COLORS.gray4, margin: 'auto 5px', fontSize: '6px' }}></i></div>
                    <NavBar_Dropdown_Left
                        id="board_layout_sheet_type_dropdown"
                        on_select={switch_board_layout_sheet_type}
                        options={board_layout_sheet_types.sheet_types}
                        display_name={board_layout_sheet_types.active_sheet_type ? board_layout_sheet_types.active_sheet_type.name : 'Select Sheet Type'}
                        className="blue_hover "
                    />
                    <NavBar_Dropdown_Left
                        id="board_layout_sheet_dropdown"
                        on_select={switch_board_layout_sheet}
                        options={board_layout_sheets.sheets}
                        display_name={board_layout_sheets.active_sheet ? board_layout_sheets.active_sheet.name : 'Select Sheet'}
                        className="blue_hover "
                    />
                </React.Fragment>
            )} */}
            {interface_mode == "design_edit_mode" && !seller_specific && !mes_editor && (
                <NavBar_Dropdown_BF
                    id="buildings_dropdown"
                    icon="building"
                    before_open={() => { handle_ui_response(JSON.stringify({ update_level_arrays: true })) }} on_select={switch_building}
                    options={level_arrays.buildings}
                    display_name={level_arrays.active_building ? level_arrays.active_building.name : 'Select Building'}
                    className="blue_hover "
                    toolbar_onclick={toolbar_on_click}
                    type='building'
                    add_on_click={level_arrays.add_building_route}
                />
            )}
            {interface_mode == "design_edit_mode" && !seller_specific && !mes_editor && (
                <React.Fragment>
                    {(viewing_level == "building" || viewing_level == "floor" || viewing_level == "room") && (
                        <NavBar_Dropdown_BF
                            id="floors_dropdown"
                            icon="floor"
                            before_open={() => { handle_ui_response(JSON.stringify({ update_level_arrays: true })) }}
                            on_select={switch_floor}
                            options={level_arrays.floors}
                            display_name={level_arrays.active_floor ? level_arrays.active_floor.name : 'Select Floor'}
                            className="blue_hover "
                            toolbar_onclick={toolbar_on_click}
                            type='floor'
                            add_on_click={level_arrays.add_floor_route}
                        />
                    )}
                    {(viewing_level == "floor" || viewing_level == "room") && (
                        <NavBar_Dropdown_BF
                            id="rooms_dropdown"
                            icon='room'
                            before_open={() => { handle_ui_response(JSON.stringify({ update_level_arrays: true })) }}
                            on_select={switch_room}
                            options={level_arrays.rooms}
                            display_name={level_arrays.active_room ? level_arrays.active_room.name : 'Select Room'}
                            className="blue_hover "
                            toolbar_onclick={toolbar_on_click}
                            type='room'
                        />
                    )}
                </React.Fragment>
            )}

        </div>
    )
}

const NavbarInfoSection = ({ work_order_details }) => {

    const alert = useAlert();
    const { seller_specific, mes_editor, handle_ui_response, set_page_loader, project_name, project_id, client_name, design_name } = useContext(DesignViewContext);
    const [project_id_overridden, set_project_id_overridden] = useState("Project ID");
    const [client_name_overridden, set_client_name_overridden] = useState("Client Name");
    const [client_details_overridden, set_client_details_overridden] = useState("Client Details");

    const get_project_fields = async () => {
        try{
            let project_fields = await get_project_overridden_fields();
            set_project_id_overridden(project_fields.project_ID_overridden);
            set_client_name_overridden(project_fields.client_name_overridden);
            set_client_details_overridden(project_fields.client_details_overridden);
        }catch(err) {
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    const rename_design = (design_id, name) => {
        if (validate_design_name(name)) {
            set_page_loader({
                show: true,
                text: 'Renaming design...'
            },

                async () => {
                    let active_design = window.Module.get_active_design()
                    try {
                        var response = await window.Promisify(window.Module.rename_design(active_design.design_id, name))
                        handle_ui_response(JSON.stringify({ "update_design_name": true }))
                        alert.success("Design renamed successfully");
                    } catch (error) {
                        alert.error('Could not rename your design')
                    }
                    active_design.delete()

                    set_page_loader({
                        show: false,
                        text: 'Please wait'
                    });
                }
            );
        } else {
            if (name && name.trim()) {
                alert.error('Special Characters Not permitted. Please modify the Design Name')
            } else {
                alert.error('Design Name cant be blank')
            }
        }
    }
    useEffect(() => {
        get_project_fields();
    }, []);

    return (
        <React.Fragment>
            {
                window.is_mes_design_edit() ?
                    <React.Fragment>
                        <Tooltip placement='bottom' title={
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', fontSize: '14px', textAlign: 'left', color: COLORS.gray2 }}>
                                <div>Project: <b>{work_order_details && work_order_details.project && work_order_details.project.name ? work_order_details.project.name : "-"}</b></div>
                                <div>Client: <b>{work_order_details && work_order_details.project && work_order_details.project.client_name ? work_order_details.project.client_name : "-"}</b></div>
                                <div>Delivery: <b>{work_order_details && work_order_details.delivery_date ? toReadableDate(work_order_details.delivery_date) : "-"}</b></div>
                                <div>Created on: <b>{work_order_details && work_order_details.created_at ? toReadableDate(work_order_details.created_at) : "-"}</b></div>
                                <div>Description: <b>{work_order_details && work_order_details.description ? work_order_details.description : "-"}</b></div>
                            </div>
                        }>
                            <div id='mes_work_order_info'>
                                {/* <div>
                                    MES
                                </div>
                                <div>
                                    {work_order_details ? work_order_details.name : ''}
                                </div> */}
                                MES
                            </div>
                        </Tooltip>
                    </React.Fragment>

                    :
                    <React.Fragment>
                        <Tooltip placement='bottom' title={
                            <div className="flex_column inf-gap-4">
                                <div>Project Name: <b>{project_name ? project_name : '-'}</b></div>
                                <div>{project_id_overridden}: <b>{project_id ? project_id : '-'}</b></div>
                                <div>{client_name_overridden}: <b>{client_name ? client_name : '-'}</b></div>
                            </div>
                        }>
                            <img id='header_project_info' style={{ marginTop: '2px', maxWidth: '25px', maxHeight: '20px', objectFit: 'contain' }} src={"/resources/images/file.svg"} />
                        </Tooltip>
                    </React.Fragment>
            }

            {
                mes_editor || window.is_mes_design_edit() ?
                    <div id='work_order_name_header' className='navbar_item_hover inf-px-2 inf-py-1 cp lines1_elipsis rounded' style={{ maxWidth: '120px' }}>{work_order_details ? work_order_details.name : ''}</div>
                    : ''
            }

            {
                !mes_editor && !seller_specific && !window.is_mes_design_edit() ?
                    <React.Fragment>
                        <ITriggerModal ModalComponent={Edit_Design_Modal} modalProps={{ rename_design, active_design: { name: design_name } }}>
                            <Tooltip placement='bottom' target={'design_name_header'} title={
                                <div>Design Name: <b>{design_name ? design_name : '-'}</b></div>
                            }>
                                <div id='design_name_header' className='navbar_item_hover inf-px-2 inf-py-1 cp lines1_elipsis rounded' style={{ maxWidth: '120px' }}>{design_name}</div>
                            </Tooltip>
                        </ITriggerModal>
                    </React.Fragment>
                    : ''
            }
        </React.Fragment>
    )
}

const DesignViewNavbarDivider = () => {
    return (
        <div style={{ width: '2px', background: 'var(--secondary-background-design-navbar, #a7a8b340)', height: 'calc( var(--infurnia-navbar-height, 52px) - 16px )' }}></div>
    )
}

const DesignViewNavbar = ({ store_details, work_order_details, wait_for_version_save_queue, is_user_admin, seller_sku_details, viewing_level, level_arrays, presentation_sheets, boq_schedules, board_layout_sheets, board_layout_sheet_types, elevation_cameras, custom_plans_cameras, socket_id_to_user_map }) => {

    const { seller_specific, mes_editor, update_view, interface_mode, remote_view, set_page_loader } = useContext(DesignViewContext)
    const history = useHistory();
    const alert = useAlert();

    const get_preview_as_designer_button_name = () => {
        var active_user = window.Module.get_active_user();
        if (active_user.admin_account) {
            return "Preview as Designer";
        } else {
            return "Return to User View";
        }
    }

    const toggle_preview_as_designer = () => {
        window.Module.toggle_preview_as_designer();
        update_view();
    }

    const toggle_fullscreen = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    const close_design = async () => {
        try {
            set_page_loader({
                show: true,
                text: 'Closing Design. Please Wait...'
            }, async () => {
                setTimeout(async () => {
                    var active_design = window.Module.get_active_design();
                    var project_id = active_design.project_id;
                    active_design.delete();

                    if (!remote_view && !seller_specific) {
                        var imgData = window.get_snapshot_data(450, 253);
                        try {

                            if (imgData != null && imgData != undefined && imgData != "") {
                                await window.Promisify(window.Module.set_design_thumbnail(imgData));
                            }

                            await wait_for_version_save_queue();

                        } catch (err) {
                            if (window['sentry_capture_exception']) {
                                window['sentry_capture_exception']("", err);
                            }
                        }
                    }

                    set_page_loader({
                        show: false,
                        text: ''
                    })

                    var url = '/';
                    if (window.redirect_status === 'infurnia') {
                        history.goBack()
                    } else {
                        let org_key = get_org_key();
                        if (org_key && window.location.origin.includes("infurnia.com")) {
                            url = "/" + org_key + url
                        }
                        history.push(url)
                    }
                }, 0);
            })
        } catch (err) {
            window.sentry_capture_exception("", err);
        }
    }

    const go_back_onclick = () => {
        if (window.debug_mode) {
            window.add_debug_log(window.two_interface.em_interface.class_type + "->draw();");
            window.add_debug_log("go_back_onclick();");
        }
        window.Module.go_back_onclick();
        update_view();
    }

    return (
        <div id="infurnia-design-navbar">
            <div className="flex_property inf-gap-3 inf-h-full">
                {/* Store Logo */}
                {
                    store_details && store_details.logo_path ?
                        <img className="infurnia-design-navbar-logo" alt="logo" src={store_details.logo_path} />
                        :
                        <Icons name={'infurnia'} className="infurnia-design-navbar-logo" />
                }

                <DesignViewNavbarDivider />

                {/* Navbar Info Section */}
                <NavbarInfoSection work_order_details={work_order_details} />

                <DesignViewNavbarDivider />

                {/* Bread Crumb Bar */}
                <DesignBreadCrumbBar seller_sku_details={seller_sku_details} viewing_level={viewing_level} level_arrays={level_arrays} presentation_sheets={presentation_sheets} boq_schedules={boq_schedules} board_layout_sheets={board_layout_sheets} board_layout_sheet_types={board_layout_sheet_types} />
            </div>

            {/* Action Center */}
            <div className="flex_property inf-gap-3">
                {/* Perspective Switcher */}
                <PerspectiveSwitcher elevation_cameras={elevation_cameras} custom_plans_cameras={custom_plans_cameras} />

                <DesignViewNavbarDivider />

                {
                    !remote_view && !seller_specific ?
                        <UncontrolledDropdown>
                            <DropdownToggle style={{ borderRadius: '50%', height: '32px', width: '32px', color: 'white', backgroundColor: '#4598F7', justifyContent: 'center', display: 'flex', alignItems: 'center', textTransform: 'uppercase', fontWeight: 500, border: 'none' }}>
                                <div style={{ borderRadius: '50%', height: '32px', width: '32px', color: 'white', backgroundColor: '#4598F7', justifyContent: 'center', display: 'flex', alignItems: 'center', textTransform: 'uppercase', fontWeight: 500, border: 'none' }}>
                                    {store_details && store_details.designer_name && store_details.designer_name.trim() ? store_details.designer_name.trim()[0] : ':)'}
                                </div>
                                {
                                    socket_id_to_user_map && Object.keys(socket_id_to_user_map).length>1?( //show +2 if there are 3 total sockets users at top right corner
                                        <div style={{position:'absolute',top:'-6px',right:'-10px',backgroundColor:'white',color:'black',border:"solid 1px darkgray",borderRadius:'50%',padding:'2px 5px',fontSize:'10px',fontWeight:'bold'}}>{"+" + (Object.keys(socket_id_to_user_map).length-1)}</div>
                                    ):''
                                }
                            </DropdownToggle>
                            <DropdownMenu persist style={{ marginTop: '11px', zIndex: 10001 }}>
                                <ITriggerModal ModalComponent={Account_Settings_Modal} modalProps={{ store_details, set_page_loader }}>
                                    <DropdownItem>
                                        Account Settings
                                    </DropdownItem>
                                </ITriggerModal>
                                <ITriggerModal ModalComponent={View_Live_Codesigners_Modal} modalProps={{ socket_id_to_user_map, set_page_loader }}>
                                    <DropdownItem>
                                        View live co-designers
                                    </DropdownItem>
                                </ITriggerModal>
                                {is_user_admin ? (<DropdownItem onClick={toggle_preview_as_designer}>
                                    {get_preview_as_designer_button_name()}
                                </DropdownItem>) : ''}
                                <DropdownItem>
                                    <ITriggerModal ModalComponent={InfurniaPlansModal}>
                                        Current Subscription
                                    </ITriggerModal>
                                </DropdownItem>
                                <DropdownItem onClick={logout}>
                                    Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        : ''
                }
                {
                    window.Module.API.segment_id && (!(window.location.pathname.split('/')[1]) || (window.location.pathname.split('/')[1] != "livspace" && window.location.pathname.split('/')[1] != "zest")) ? (
                        // <img className='cp' onClick={() => { document.getElementById("my_custom_intercom_link").click() }} data-toggle="dropdown" data-placement="bottom" title="Need Help" src={'/resources/nav_icons_new/need_help.svg'}></img>
                        <Icons name={'need_help'} className={'design-view-navbar-icons'} onClick={() => { document.getElementById("my_custom_intercom_link").click() }} title="Need Help"/>
                    ) : ''
                }
                {window.Module.API.segment_id && (!(window.location.pathname.split('/')[1]) || (window.location.pathname.split('/')[1] != "livspace" && window.location.pathname.split('/')[1] != "zest")) && (<a className="blue_hover black_text hover_toolbar" style={{ cursor: 'pointer', display: "none" }} id="my_custom_intercom_link"><img data-toggle="tooltip" data-placement="bottom" title="Need Help" src={'/resources/nav_icons_new/need_help.svg'}></img></a>)}
                {/* <div className="hover_toolbar" style={{ cursor: 'pointer' }} onClick={toggle_fullscreen}> */}
                    {/* <img data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" src={'/resources/nav_icons_new/fullscreen.svg'}></img> */}
                    <Icons name={'fullscreen'} title={"Toggle Fullscreen"} className={'design-view-navbar-icons'} onClick={toggle_fullscreen}/>
                {/* </div> */}
                {!remote_view && !seller_specific && !mes_editor && interface_mode == "design_edit_mode" && !(window.is_mes_design_edit()) && (
                    // <div className="hover_toolbar" style={{ cursor: 'pointer' }} onClick={close_design}>
                    // {/* <img data-toggle="tooltip" data-placement="bottom" title="Back" src={'/resources/nav_icons_new/close.svg'}></img> */}
                    <Icons name={'cross_thin'} title={"Back"} className={'design-view-navbar-icons'} onClick={close_design}/>
                // </div>
            )}
                {!remote_view && interface_mode != "design_edit_mode" && (
                    // <div className="hover_toolbar" style={{ cursor: 'pointer' }} onClick={go_back_onclick}>
                    // {/* <img data-toggle="tooltip" data-placement="bottom" title="Back To Designing" src={'/resources/nav_icons_new/back.svg'}></img> */}
                    <Icons name={'back'} title={"Back to Designing"} className={'design-view-navbar-icons'} onClick={go_back_onclick}/>
                // </div>
            )}
            </div>
        </div>
    )
}

export default DesignViewNavbar