/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { Router, Route, Switch, useHistory, Redirect} from 'react-router-dom';
import { Table, Col, Row, Card, CardBody, CardHeader, Button, CardColumns, CardText } from "reactstrap";
import { COLORS } from './utils/color';
import useStateCallback from './utils/useStateCallback.js';
import Sign_up from "./views/Sign_up/";
import {Designs} from "./views/Designs/";
import {MainPage} from "./views/MainPage/";
import EditDesign from "./views/EditDesign/";
import ResetPassword from "./views/ResetPassword/";
import { useAlert } from 'react-alert'
import DeleteNotification from "./components/DeleteNotification/";
import { confirmAlert } from 'react-confirm-alert'; 
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import general_fetch from './utils/fetch';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.css';

import dummy_tick from './utils/dummy_tick';
import Loader from './components/Loader';
import { all_legal_routes_initial } from './models/constants.js';
import get_org_key from './utils/get_org_key.js';
import logout from './utils/logout.js';
import Home from './views/Home/index.js';
import Feature_Guide from './components/Feature_Guide/index.js';
import {useTranslation} from 'react-i18next'
import { handleError } from './utils/errors.js';

var version_save_underprocess_timer;
var version_save_timeout_grace_period_active = false;
var version_save_underprocess_firing_id;
window.clear_version_save_timeout_timer = () => {
    clearTimeout(version_save_underprocess_timer);
    version_save_timeout_grace_period_active = false;
};

const styles = ({
    
        filler_table_header_style:{
            color:'black', 
            fontSize:'14px'
        },
        filler_table_style:{
            backgroundColor:"white",
            border:'0px',
        }
    
})

const unauthenticated_routes=[
    // '/login/','/login',
    // '/login/admin/','/login/admin',
    // '/login/affiliate/','/login/affiliate',
    // '/login/social_signup/','/login/social_signup',
    // '/reset_password','/reset_password/'
]

const Filler = ({ initialized, message}) => (
    <Table className="align-items-center table-flush text-center" responsive>
        <thead className="text-center">
            <tr>
                { initialized ? (
                    <th scope="col" style={styles.filler_table_style}>
                        <span style={styles.filler_table_header_style}>No data to show.</span><br/> <span style={styles.filler_table_header_style}>{message?message:''}</span> <br /> &nbsp;
                    </th>
                ):(
                    <th scope="col">
                        <Row>
                            <Col xs={12} className="text-center">
                                <span style={styles.filler_table_header_style}><b>Fetching data...</b></span><br/> <br /> Please wait...<br /> <br />
                            </Col>
                        </Row>
                    </th>
                )}
            </tr>
        </thead>
    </Table>	
);

const Login_reroute = ({org_key, target, logout}) => {
    useEffect(() => {
        logout();
    },[]);

    return (
        <div></div>
    )
}

window.is_mes_design_edit = () => window.location.href.includes('/mes-design-edit/');
window.is_remote_view = () => window.location.href.includes('/share/') || window.location.href.includes('/design-version/') || window.location.href.includes('/bake-view/');
window.is_seller_specific = () => window.location.href.includes('/seller/') || window.location.href.includes('/component/');
window.is_mes_editor = () => window.location.href.includes('/mes-editor/');

var time_offset = 0;

const App = () => {
    const alert = useAlert();
    const {t} = useTranslation();
    // const [ user, set_user ] = useState('');
    const [ store_details, set_store_details ] = useState({});
    const [ user_loaded, set_user_loaded ] = useState(false);
    const [ init_needed, set_init_needed ] = useState(false);
    const [ remote_view, set_remote_view ] = useState(true); 
    const [ design_version_only, set_design_version_only ] = useState(false); 
    const [ baking_view, set_baking_view ] = useState(false); 
    const [ version_outdated, set_version_outdated ] = useState(false);
    const [ server_under_maintainence, set_server_under_maintainence ] = useState(false);
    const [is_store_suspended, set_is_store_suspended] = useState(false);
    const [is_user_active, set_is_user_active] = useState();
    const [ version_checked, set_version_checked ] = useState(false);
    const [ device_isnt_supported, set_device_isnt_supported ] = useState(false); 
    const [ login_error, set_login_error ] = useState(false);
    const [ is_user_admin, set_is_user_admin] = useState(false);
    const [infurnia_path, set_infurnia_path] = useState({
        react: "https://design.infurnia.com"
    });
    const history = useHistory();

    const [org_key_logo, set_org_key_logo] = useState("");
    const [org_key_id, set_org_key_id] = useState("");
    const [org_key_name, set_org_key_name] = useState("");
    const [triggered_by, set_triggered_by] = useState({key: 'refresh'})

    const [ insufficient_roles, set_insufficient_roles ] = useState(false);

    const [ re_render, set_re_render ] = useState(false);
    const forceReRender = () => set_re_render(!re_render)


    const react_version = "stage3_v0.164.0";

    const [page_loader,set_page_loader] = useStateCallback({
        show: false,
        text: "Please wait while we set up everything..."
    });

    const [three_d_loader,set_three_d_loader] = useStateCallback({
        show: false,
        text: "Loading 3D Content..."
    });

    const [page_loader_outer,set_page_loader_outer] = useStateCallback({
        show: true,
        text: "Please wait while we set up everything..."
    });

    useEffect(() => {
        return () => {
                window.addEventListener('beforeunload', function (event) {
                    if(triggered_by.key == 'saved')
                        event.stopImmediatePropagation();
                });
        }
    })


    // const load_hatch_patterns = () =>{
    //     fetch("/resources/hatch_patterns/hatch_txt_file.txt").then(o => o.text()).then(o => window.FS.writeFile("hatch_txt_file.txt",o));
	// 	fetch("/resources/hatch_patterns/hatch_txt_file2.txt").then(o => o.text()).then(o => window.FS.writeFile("hatch_txt_file2.txt",o));

    // }

    window.login_validator = async () => {
        if(!user_loaded){
            //dont want to do any validation before init and user loaded is done
            return;
        }
        var login_validation = window.Module.validate_login_status();
        if(login_validation){
            login_validation = JSON.parse(login_validation);
            if(login_validation.message == "valid"){
                // console.log("Validated the login. All good");
            }else if(login_validation.message == "logged_out"){
                console.log("Validated the login. User is logged out");
                let path = window.location.pathname;
                logout();
            }else if(login_validation.message == "different_user"){
                console.log("Validated the login. User is different");
                window.location.reload();
            }
        }
    }

    const socket_init = () => {
        try{
            //disconnecting existing socket connection if present
            if(window.socket){
                window.socket.disconnect();
            }
        }catch(err){
            console.error(err);
        }

        window.socket = window.io.connect(window.Module.API.server_path,{transports:['websocket']});
        window.socket.on('connect',function(data) {
            window.Module.Socket.on('connect',"\"\"");
            console.log("socket connected at "+performance.now());
            window.login_validator();
        });
        window.socket.on('disconnect',function(data) {
            window.Module.Socket.on('disconnect',"\"\"");
            console.log("socket disconnected at "+performance.now());
        });

        window.socket.on('please_logout',function(data) {
            if(window.socket){
                window.socket.disconnect();
            }
            // var url = "/login?e=multilogin";
            // (get_org_key()?(url = "/"+get_org_key() + url):null);
            // history.push(url)
            let path = window.location.pathname;
            logout(`e=multilogin`);
        });

        window.socket.on('please_refresh',function(data) {
            window.location.reload();
        });

        // window.socket.on('version_updated',function(data){
        //     const exclude_list = ["localhost", "admin_local_dev", "devdesign", "testdesign", "testpreproddesign","stage2design","stage1design","stage4design", "localdesign"];
        //     if(!exclude_list.some(x => window.location.origin.includes(x))){
        //         set_version_outdated(true);
        //     }
        // })

        window.socket.on("server_timestamp",function(data){
            time_offset = (new Date(data.timestamp) - new Date());
        })

        window.socket.on('version_info',function(data){
            if(data && data.response_code == 1){
                var remote_core = data.data.core_design
                var remote_react = data.data.react_app

                var current_core_version = window.Module.get_infurnia_version_core()
                var change_detected = false;

                if(remote_react != react_version){
                    // console.log("React is out of date, current:"+ react_version + " remote: " + remote_react);
                    change_detected = true;
                }

                // if(remote_core != current_core_version){
                //     change_detected = true;
                // }

                if(change_detected){
                    const exclude_list = ["localhost", "admin_local_dev", "devdesign", "testdesign", "testpreproddesign","stage2design","stage3design", "stage4design", "stage5design", "stage6design", "stage7design", "stage8design", "stage1design","stage4design","stagedesign", "localdesign"];
                    if(!exclude_list.some(x => window.location.origin.includes(x))){
                        set_version_outdated(true);
                    }
                }
            }
        })


        var onevent = window.socket.onevent;
        window.socket.onevent = function (packet) {
            var args = packet.data || [];
            onevent.call (this, packet);    // original call
            packet.data = ["*"].concat(args);
            onevent.call(this, packet);      // additional call to catch-all
        };
        window.socket.on('*',function(event,data) {
            var data_stringified;
            if(data){
                data_stringified = JSON.stringify(data);
            }else{
                data_stringified = '""';
            }
            window.Module.Socket.on(event,data_stringified);

            if(window.debug_mode && event == "save_response"){
                console.log(data)
                alert.info((data.response_code == 1?"successfully saved: ":"failed to save: ") + data.data.design_version.name);
            }

            if(event == "data_update"){
                set_page_loader({show: false,text: "Please wait..."})
            }else if(event == "save_response" && data.response_code == 1){
                if(window.rtx_save_response_in_progress){
                    window.rtx_save_response_in_progress = false;
                }
                window.clear_version_save_timeout_timer();
            }
        });

        window.socket.on('close',function(data) {
            console.log("socket connection was closed. reopening it");
            window.socket.open();
        });

        if(window.socketVersionLoop){
            clearInterval(window.socketVersionLoop)
        }

        // window.socketVersionLoop = setInterval(function(){
        //     if(window.socket){
        //         window.socket.emit('version_check',{
        //             frontend_version: react_version,
        //             core_version: window.Module.get_infurnia_version_core()
        //         });
        //     }
        // },30000);

        window.socketVersionLoop = setInterval(function(){
            if(window.socket){
                window.socket.emit('get_remote_version');
            }
        },30000);

        return new Promise((res, rej) => {
            window.socket.once('connect',function(data) {
                res();
            });
        });
    }

    const activate_coview_iframe_handler = () => {
        if(window['MutationObserver'] == undefined){
            return;
        }
        
        const mutation_callback = (mutationList, observer) => {
          for (const mutation of mutationList) {
            if (mutation.type === "childList" && ( (mutation.target && mutation.target.id == "cov-send-preview") )){
              console.log("Coview preview iframe found, handling corp/coep settings");
              var iframe_id = "cov-preview-iframe";
              var iframe_find = document.getElementById(iframe_id);
              if(iframe_find && iframe_find.parentNode && iframe_find.credentialless == false){
                  var parent_find = iframe_find.parentNode;
                  parent_find.removeChild(iframe_find);
                  iframe_find.credentialless = true;
                  parent_find.appendChild(iframe_find);
              }
            }else if (mutation.type === "childList" && mutation.addedNodes.length>0 && mutation.addedNodes[0].id == "coview-container") {
              console.log("Coview container found, transferring observer to coview");
              observer.disconnect();
              observer.observe(mutation.addedNodes[0],  { attributes: false, childList: true, subtree: true });
            }
          }
        };

        var observer = new MutationObserver(mutation_callback);
        observer.observe(document.body,  { attributes: false, childList: true, subtree: false });
      }

    /**
     * Handles view login for remote views
     * @param {string} branch_id - The branch ID to check
     * @param {boolean} is_design_version_only - Whether this is a design version view
     * @param {boolean} is_baking_view - Whether this is a baking view
     * @returns {Promise<any>} - Response from view login attempt
     */
    const handle_remote_view_login = async (branch_id, is_design_version_only, is_baking_view) => {
        try {
            let view_login_resp;
            if (is_baking_view || is_design_version_only) {
                view_login_resp = await window.Promisify(window.Module.view_login_version_id(branch_id));
            } else {
                view_login_resp = await window.Promisify(window.Module.view_login(branch_id));
            }
            
            view_login_resp = JSON.parse(view_login_resp);
            
            if (view_login_resp && view_login_resp.response_code === -1) {
                console.log("View login code failed");
                
                if (view_login_resp.code === "DESIGN_BRANCH_DOES_NOT_EXIST") {
                    console.log("DESIGN_BRANCH_DOES_NOT_EXIST");
                    set_page_loader({show: true, text: "design_doesnt_exist"});
                    set_login_error(true);
                } else if (view_login_resp.code === "DESIGN_BRANCH_LINK_SHARING_DISABLED") {
                    console.log("DESIGN_BRANCH_LINK_SHARING_DISABLED");
                    set_page_loader({show: true, text: 'unauthorized_access'});
                    set_login_error(true);
                } else if (view_login_resp.code === "DESIGN_VERSION_DOES_NOT_EXIST") {
                    console.log("DESIGN_VERSION_DOES_NOT_EXIST");
                    set_page_loader({show: true, text: "design_doesnt_exist"});
                    set_login_error(true);
                }
                
                return view_login_resp;
            }
            
            return view_login_resp;
        } catch (err) {
            console.error("Error in handle_remote_view_login:", err);
            handleError(err);
            throw err;
        }
    };

    /**
     * Checks and handles user authentication and roles
     * @param {boolean} is_remote_view - Whether this is a remote view
     * @param {boolean} target_is_admin - Whether this is targeting admin page
     * @param {boolean} is_seller_specific - Whether this is a seller-specific view
     * @returns {Promise<string>} - Response status from authentication check
     */
    const handle_authentication = async (is_remote_view, target_is_admin, is_seller_specific) => {
        try {
            let response = await window.Promisify(window.Module.get_user_details());
            
            if (is_remote_view && (response === "unauthorized" || response === "user_role_insufficient" || response === "error")) {
                const split_path = window.location.pathname.split('/');
                const branch_id = split_path[split_path.length - 1];
                
                const view_login_resp = await handle_remote_view_login(
                    branch_id,
                    window.location.href.includes('/design-version/'),
                    window.location.href.includes('/bake-view/')
                );
                
                // If view login failed, return early
                if (view_login_resp && view_login_resp.response_code === -1) {
                    return response;
                }
                
                // Try to get user details again after view login
                response = await window.Promisify(window.Module.get_user_details());
                
                if (response === "unauthorized" || response === "error") {
                    alert.error("Link sharing is disabled for the current design", {timeout: 20000});
                    set_page_loader({show: false, text: "Please wait..."});
                    return response;
                } else if (response === "user_role_insufficient") {
                    // Ignoring user roles in remote view
                    response = "success";
                }
            }
            
            if (response === "SCHEDULED_MAINTAINANCE") {
                set_server_under_maintainence(true);
                const server_maintenance_interval = setInterval(async () => {
                    const check_response = await window.Promisify(window.Module.get_user_details());
                    if (check_response !== "SCHEDULED_MAINTAINANCE") {
                        clearInterval(server_maintenance_interval);
                        window.location.reload();
                        return;
                    }
                }, 5000);
                return response;
            }
            
            if (response === "needs_sign_up") {
                set_init_needed(true);
                const url = "/login/social_signup";
                (get_org_key() && window.location.origin.includes("infurnia.com") ? 
                    history.push("/" + get_org_key() + url) : 
                    history.push(url));
                return response;
            } else if (response === "unauthorized" || response === "user_role_insufficient" || response === "error") {
                if (!(response === "user_role_insufficient" && (target_is_admin || is_seller_specific))) {
                    set_init_needed(true);
                    if (response === "user_role_insufficient") {
                        set_insufficient_roles(true);
                        alert.error("You do not have designer access, Please contact your administrator");
                    } else {
                        logout();
                    }
                }
                return response;
            }
            
            return response;
        } catch (err) {
            console.error("Error in handle_authentication:", err);
            handleError(err);
            return "error";
        }
    };

    /**
     * Handles user platform detection and setup
     */
    const setup_user_platform = () => {
        try {
            const is_windows = window.navigator.platform.indexOf('Win') > -1;
            const is_linux = window.navigator.platform.indexOf('Linux') > -1;
            const is_mac = window.navigator.platform.indexOf('Mac') > -1;
            const is_chrome_os = window.navigator.platform.indexOf('CrOS') > -1;
            
            let platform_user = "";
            if (is_windows) {
                platform_user = "windows";
            } else if (is_linux) {
                platform_user = "linux";
            } else if (is_mac) {
                platform_user = "mac";
            } else if (is_chrome_os) {
                platform_user = "chrome_os";
            }
            
            window.Module.set_user_platform(platform_user);
        } catch (err) {
            console.error("Error in setup_user_platform:", err);
            handleError(err);
        }
    };

    /**
     * Handles white labelling redirection if needed
     * @param {string} org_key - The organization key
     * @param {boolean} is_remote_view - Whether this is a remote view
     * @returns {Promise<void>}
     */
    const handle_white_label_redirection = async (org_key, is_remote_view) => {
        if (!window['Module'] || !window.Module['get_active_user'] || is_remote_view) {
            return;
        }
        
        try {
            const active_user = window.Module.get_active_user();
            if (active_user && active_user.white_labelling_url && active_user.white_labelling_url !== org_key) {
                const existing_org_key = org_key;
                let existing_pathname = window.location.pathname;
                
                if (existing_org_key) {
                    existing_pathname = existing_pathname.replace("/" + existing_org_key, "");
                }
                
                const final_path = "/" + active_user.white_labelling_url + existing_pathname;
                
                if (window.location.origin.includes('infurnia.com')) {
                    history.push(final_path);
                } else {
                    const new_origin = infurnia_path.react;
                    window.location.href = new_origin + final_path;
                }
                
                await set_org_details();
            } else if (active_user && !active_user.white_labelling_url && org_key) {
                let existing_pathname = window.location.pathname;
                
                if (org_key) {
                    existing_pathname = existing_pathname.replace("/" + org_key, "");
                }
                
                if (window.location.origin.includes('infurnia.com')) {
                    history.push(existing_pathname);
                } else {
                    const new_origin = infurnia_path.react;
                    window.location.href = new_origin + existing_pathname;
                }
                
                await set_org_details();
            }
        } catch (err) {
            console.error("Error in handle_white_label_redirection:", err);
            handleError(err);
        }
    };

    /**
     * Validates that the user belongs to the correct store
     * @param {string} org_key_id - The organization key ID
     * @param {boolean} is_remote_view - Whether this is a remote view
     * @returns {Promise<boolean>} - Whether the validation succeeded
     */
    const validate_user_store = async (org_key_id, is_remote_view) => {
        if (!org_key_id || is_remote_view) {
            return true;
        }
        
        try {
            const active_user = window.Module.get_active_user();
            const current_store_id = active_user.store_id;
            
            if (org_key_id !== current_store_id) {
                logout();
                return false;
            }
            
            return true;
        } catch (err) {
            console.error("Error in validate_user_store:", err);
            handleError(err);
            return false;
        }
    };

    /**
     * Sets up analytics and error tracking
     * @param {boolean} is_remote_view - Whether this is a remote view
     */
    const setup_analytics_and_error_tracking = (is_remote_view) => {
        try {
            const active_user = window.Module.get_active_user();
            
            if (active_user.admin_account) {
                set_is_user_admin(true);
            }
            
            // Only set up Sentry for production environments
            const is_dev_environment = window.location.origin.includes('localhost') || 
                window.location.origin.includes('admin_local_dev') || 
                window.location.origin.includes('devdesign') || 
                window.location.origin.includes('localdesign') || 
                window.location.origin.includes('testdesign') || 
                window.location.origin.includes('testpreproddesign');
            
            if (!is_dev_environment) {
                const scope = Sentry.getCurrentScope();
                scope.setUser({
                    id: active_user.designer_id,
                    email: active_user.designer_email
                });
                
                scope.setTag("org_name", active_user.store_name);
                scope.setTag("org_id", active_user.store_id);
                scope.setTag("corelib_session_id", window.Module.API.corelib_session_id);
                scope.setTag("react_version", react_version);
                scope.setTag("core_version", window.Module.get_infurnia_version_core());
            }
            
            const infurnia_user = active_user.designer_email.includes("@infurnia.com");
            if (infurnia_user) {
                active_user.is_suspended = false;
            }
            
            let active_add_ons = JSON.parse(window.Module.get_active_user().get_active_addons());
            set_is_store_suspended(active_user.is_suspended);
            set_is_user_active((active_user.active || infurnia_user) ? true : false);
            set_store_details({
                logo_path: active_user.get_logo_path_for_application_navbar(),
                designer_name: active_user.designer_name,
                designer_email: active_user.designer_email,
                store_name: active_user.store_name,
                suspension_status: active_user.is_suspended,
                active_add_ons
            });
            
            // Set up analytics if available and not in remote view
            if (!is_remote_view && window['analytics']) {
                try {
                    window.analytics.identify(active_user.designer_id, {
                        name: active_user.designer_name,
                        email: active_user.designer_email,
                        user_id: active_user.designer_id,
                        company_id: active_user.store_id,
                        createdAt: active_user.created_at
                    });
                } catch (err) {
                    console.error(err);
                }
            }
        } catch (err) {
            console.error("Error in setup_analytics_and_error_tracking:", err);
            handleError(err);
        }
    };

    /**
     * Sets up event listeners for handling unsaved changes and network state
     * @param {boolean} is_seller_specific - Whether this is a seller-specific view
     */
    const setup_event_listeners = (is_seller_specific) => {
        try {
            window.onbeforeunload = function (e) {
                if ((window.Module.get_version_save_queue_length() > 0 || 
                    (is_seller_specific && window.Module.get_offline_save_queue_length() > 0)) && 
                    !window["ignore_onbeforeunload"]) {
                    e = e || window.event;
                    
                    // For IE and Firefox prior to version 4
                    if (e) {
                        e.returnValue = 'You might loose some unsaved changes. Do you want to quit ?';
                    }
                    
                    // For Safari
                    return 'You might lose some unsaved changes. Do you want to quit ?';
                }else if(window.socket){
                    window.socket.emit("change_room","null");
                }
            };
            
            window.removeEventListener('online', update_online_status);
            window.removeEventListener('offline', update_online_status);
            
            window.addEventListener('online', update_online_status);
            window.addEventListener('offline', update_online_status);
        } catch (err) {
            console.error("Error in setup_event_listeners:", err);
            handleError(err);
        }
    };

    /**
     * Setup MES design edit mode
     * @returns {Promise<boolean>} - Whether setup was successful
     */
    const setup_mes_design_edit = async () => {
        try {
            if (window.location.pathname.includes("/design/") || window.location.pathname.includes("/mes-design-edit/")) {
                let curr_branch_id;
                if (window.location.pathname.includes("/design/")) {
                    curr_branch_id = window.location.pathname.split("/design/")[1];
                } else {
                    curr_branch_id = window.location.pathname.split("/mes-design-edit/")[1];
                }
                
                const is_mes_branch = window.Module.check_if_branch_id_is_mes_branch(curr_branch_id);
                const org_key = get_org_key();
                
                if (window.location.pathname.includes("/design/") && is_mes_branch) {
                    let new_url = "/mes-design-edit/" + curr_branch_id;
                    console.log("new_url:" + new_url);
                    (org_key && window.location.origin.includes("infurnia.com") ? (new_url = "/" + org_key + new_url) : null);
                    history.push(new_url);
                    return true;
                } else if (window.location.pathname.includes("/mes-design-edit/") && !is_mes_branch) {
                    let new_url = "/design/" + curr_branch_id;
                    console.log("new_url:" + new_url);
                    (org_key && window.location.origin.includes("infurnia.com") ? (new_url = "/" + org_key + new_url) : null);
                    history.push(new_url);
                    return false;
                }
                
                return window.is_mes_design_edit();
            }
            
            return false;
        } catch (err) {
            console.error("Error in setup_mes_design_edit:", err);
            handleError(err);
            return false;
        }
    };

    /**
     * Main initialization function
     * @returns {Promise<any>} - The initialization response
     */
    const init = async () => {
        try {
            // Check if we're in special view modes
            const is_mes_design_edit = await setup_mes_design_edit();
            const is_remote_view = window.is_remote_view();
            const is_design_version_only = window.location.href.includes('/design-version/');
            const is_baking_view = window.location.href.includes('/bake-view/');
            const is_seller_specific = window.is_seller_specific();
            const is_mes_editor = window.is_mes_editor();
            const target_is_admin = window.location.href.includes('/admin/');
            
            const org_key = get_org_key();
            
            // Apply appropriate settings based on view mode
            if (is_mes_design_edit) {
                window.Module.set_mes_design_edit();
                document.body.classList.add('mes_design_edit_mode');
            } else {
                document.body.classList.remove('mes_design_edit_mode');
            }
            
            if (is_seller_specific) {
                window.Module.set_seller_specific();
            }
            
            if (is_mes_editor) {
                window.Module.set_mes_editor();
            }
            
            // Setup user platform detection
            setup_user_platform();
            
            // Check authentication and handle any issues
            const auth_response = await handle_authentication(is_remote_view, target_is_admin, is_seller_specific);
            if (auth_response === "SCHEDULED_MAINTAINANCE" || 
                auth_response === "needs_sign_up" || 
                auth_response === "unauthorized" || 
                auth_response === "user_role_insufficient" || 
                auth_response === "error") {
                return auth_response;
            }
            
            // Handle white label redirections if needed
            await handle_white_label_redirection(org_key, is_remote_view);
            
            // Validate the user belongs to the correct store
            const store_validated = await validate_user_store(org_key_id, is_remote_view);
            if (!store_validated) {
                return auth_response;
            }
            
            // Setup analytics and error tracking
            setup_analytics_and_error_tracking(is_remote_view);
            
            // Set view mode states
            set_remote_view(is_remote_view);
            set_design_version_only(is_design_version_only);
            set_baking_view(is_baking_view);
            
            // Initialize resources
            await window.Promisify(window.Module.resource_init(is_remote_view));
            
            // Setup event listeners for handling unsaved changes and network status
            setup_event_listeners(is_seller_specific);
            
            return auth_response;
        } catch (err) {
            console.error("Error in init:", err);
            // Add more context to the error for easier identification
            if (err) {
                // Add location context if not present
                if (!(err.message && err.message.includes("init function"))) {
                    err.message = `[init function] ${err.message || ''}`;
                }
            }
            handleError(err);
            return "error";
        }
    };

    const unhook = (unhook_info) => {
        // if(window['analytics']){
        //     try{
        //         window.analytics.track('auto', {
        //             "category": "unhook",
        //             "label": unhook_info,
        //             "company_id": window.Module.get_active_user().store_id,
        //             "value": 1
        //         });
        //     }catch(err){
        //         console.error(err);
        //     }
        // }
    }

    const wait_for_version_save_queue = () => {
		return new Promise(function(resolve,reject){
			var length_at_start_of_wait = window.Module.get_version_save_queue_length();
			if(length_at_start_of_wait > 0){
				set_page_loader({show: true,text: "Saving your design. Do not Refresh. Progress - 0/" + length_at_start_of_wait});

				var interval = setInterval(function(){
				    set_page_loader({show: true,text: "Saving your design. Do not Refresh. Progress - "+ (length_at_start_of_wait - window.Module.get_version_save_queue_length()) +"/" + length_at_start_of_wait});

					if(window.Module.get_version_save_queue_length() == 0){
				        set_page_loader({show: false,text: "Please wait..."});
						clearInterval(interval)
						resolve()
					}
				},100)
			}else{
				resolve()
			}
		})
	}

    const update_online_status = (event) => {
        if (event.type == 'offline') {
            try {
                window.Module.API.is_online = false;
                console.log("You are now offline");
                set_page_loader({show: true,text: "You are offline. Please check your internet connection."});
            }
            catch(err) {
                console.log("error from app js; set page loader not showing!")
            }
        }
        else if (event.type == 'online') {
            try{
                window.Module.API.is_online = true;
                set_page_loader({show: false,text: "Please wait..."});
                console.log("You are now online");
                alert.success("You are now online");
            }
            catch(err) {
                console.log("error from app js; set page loader not showing!")
            }
        }
    }

    const submit_for_delete_confirm = (onSubmit, onCancel, message, customButtonJson) => {
        confirmAlert({
          title: 'Confirm to submit',
          message: message,
          customUI: ({ onClose }) => {
            return (
                <DeleteNotification onSubmit={onSubmit} onCancel={onCancel} onClose={onClose} message={message} customButtonJson={customButtonJson}/>
            );
          }
        });
    };
    
    const isMobile = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    const isChromium = () => {
        return window['chrome'] != undefined
    }

    const handle_version_save_timeout = () => {
        let version_save_actually_done = window.Module.check_if_active_version_save_attempt_is_done();

        if(!version_save_actually_done){
            var current_queue_length = window.Module.get_version_save_queue_length();
            if(current_queue_length == 1 && !version_save_timeout_grace_period_active){
                //if there is only 1 version save in the queue, it is most likely because thread is blocked.
                //if there are more than 1, it means thread wasnt blocked (user was able to perform more actions)
                console.log("There is only 1 version in save queue, giving grace period ");
                version_save_timeout_grace_period_active = true;
                version_save_underprocess_timer = setTimeout(function() {
                    handle_version_save_timeout();
                }, 250000)
            }else{
                console.error("Design Changes are not getting saved.. Reloading to last saved state in 3 seconds. firing id: " + version_save_underprocess_firing_id);
                set_page_loader({show: true,text: "Design Changes are not getting saved.. Reloading to last saved state in 3 seconds."});
            
                try{
                    throw "Design Changes are not getting saved.. Reloading to last saved state in 3 seconds."
                }catch(err){
                    if(!is_store_suspended){
                        err.constructor.name.includes("Error") != true?err=new Error(err):null;
                        err.name = "Captured error in version_save timeout : " + err.name;
                        console.error(err);
                        window.sentry_capture_exception("",err);
                    }

                    setTimeout(() => {
                        unhook("version save timed out");
                        window.ignore_onbeforeunload = true;
                        window.location.reload();
                    },3000)
                }
            }
        }else{
            window.clear_version_save_timeout_timer();
        }
    }

    useEffect(() => {
        // window.set_page_loader = set_page_loader;
        window.portal = "design";

        if(!window.location.origin.includes("infurnia.com")){
            document.title = window.location.origin.split('.')[1];
            var fav_link = document.querySelector("link[rel~='icon']");
            if(fav_link){
                fav_link.href = window.location.origin + "/blank_image.png";
            }
        }
        
        if(/*isMobile() || */!window['SharedArrayBuffer'] || !isChromium()){
            set_page_loader({
                show: false,
                text: "Please wait..."
            })
            set_page_loader_outer({
                show: false,
                text: "Please wait..."
            })
            set_device_isnt_supported(true);
        }else{
            window.wasm_loaded_promise.then(async () => {
                set_page_loader_outer({
                    show: true,
                    text: "Fetching resources..."
                },async () => {
                    //await dummy_tick(0);
                    infurnia_path.react = window.Module.API.react_app_path;
                    if(window.location.origin == "https://design2.infurnia.com"){
                        window.Module.API.react_app_path = window.location.origin;
                    }
                    
                    if(window.location.origin == "https://stage1design.infurnia.com" || window.location.origin == "https://stage2design.infurnia.com" || window.location.origin == "https://stage3design.infurnia.com" || window.location.origin == "https://stage4design.infurnia.com" ||  window.location.origin == "https://stage5design.infurnia.com"){
                        var custom_stage = window.location.origin.split('design')[0].split('https://')[1];
                        window.Module.API.react_app_path = window.location.origin;
                        window.Module.API.admin_path = "https://" + custom_stage + "admin.infurnia.com";
                        window.Module.API.login_path = "https://" + custom_stage + "login.infurnia.com";
                    }
                    
                    if(!window.location.origin.includes("infurnia.com")){
                        window.Module.API.react_app_path = window.location.origin;
                        window.Module.API.admin_path = "https://admin." + window.location.origin.split('.')[1] + "." + window.location.origin.split('.')[2];
                        window.Module.API.login_path = "https://login." + window.location.origin.split('.')[1] + "." + window.location.origin.split('.')[2];
                    }

                    if(!window.location.origin.includes('localhost') && !window.location.origin.includes('admin_local_dev') && !window.location.origin.includes('devdesign') && !window.location.origin.includes('localdesign') && !window.location.origin.includes('testdesign') && !window.location.origin.includes('testpreproddesign')){
                        var sentry_integrations = [Sentry.browserTracingIntegration()];
                        var extra_sentry_properties = {};
                        // if(window.location.origin.includes("preproddesign.infurnia.com")){
                            sentry_integrations.push(Sentry.replayIntegration({
                                maskAllText: false,
                                blockAllMedia: false
                            }));
                            sentry_integrations.push(Sentry.replayCanvasIntegration({
                                maskAllText: false,
                                blockAllMedia: false
                            }));
                            extra_sentry_properties.replaysSessionSampleRate = 0;
                            extra_sentry_properties.replaysOnErrorSampleRate = 1.0;
                        // }
                        Sentry.init({
                            dsn: "https://b5631d5055c24269b02efbe048b73721@o932499.ingest.sentry.io/6212122",
                            environment: window.Module.API.application_env,
                            integrations: sentry_integrations,
                            ...extra_sentry_properties,
                            // integrations: [new BrowserTracing()],
                            // We recommend adjusting this value in production, or using tracesSampler
                            // for finer control
                            tracesSampleRate: 1.0,
                            debug: false
                        });
                    }

                    console.log("Infurnia version " + react_version);
    
                    window['out'] = console.log.bind(console);
                    window['err'] = console.error.bind(console);

                    await socket_init();

                    window.wrapper_socket_emit = async (message, data) => {
                        if(data && data.constructor.name == 'Object'){
                            //request_timestamp should be current time + window.time_offset. time_offset is a date diff ( Date(a) - Date(b) )
                            data.request_timestamp = new Date(Date.now() + time_offset);
                        }
                        if(message == "restore_version_with_components"){
                            set_page_loader({show: true,text: "Please wait..."})
                        }else if(message == "save"){
                            if(window.debug_mode){
                                alert.info("trying to save: "+data.name);
                            }

                            if(data.version){
                                version_save_underprocess_firing_id = data.version;
                            }
                    
                            window.clear_version_save_timeout_timer();
                    
                            version_save_underprocess_timer = setTimeout(function() {
                                handle_version_save_timeout();
                            }, 50000);
                        }
                    
                        window.socket.emit(message, data);
                    }
    
                    await set_org_details();
                    
                    var init_resp = "";
                    if(!unauthenticated_routes.find(o => window.location.pathname.includes(o))){
                        try{
                            init_resp = await init();
                        }catch(err){
                            console.error(err)
                        }
                    }else{
                        set_init_needed(true);
                    }
    
                    set_page_loader_outer({
                        show: false,
                        text: "Please wait..."
                    },async () => {
                        set_user_loaded(true);
                    });
                })
            })
        }

    }, []);

    const set_org_details = async () => {
        var org_key = get_org_key()

        if(org_key){
        try{
            var resp_store_white_labelling = await general_fetch({url:'/store/get_white_labelling_details', body:{white_labelling_url:org_key}})
            if(resp_store_white_labelling){
                if(resp_store_white_labelling && resp_store_white_labelling.id){
                set_org_key_id(resp_store_white_labelling.id);
                set_org_key_logo(window.Module.API.server_path + "/" + resp_store_white_labelling.logo_path);
                set_org_key_name(resp_store_white_labelling.name);

                if(!window.location.origin.includes("infurnia.com")){
                    var fav_link = document.querySelector("link[rel~='icon']");
                    if(fav_link){
                        fav_link.href = window.Module.API.server_path + "/" + resp_store_white_labelling.logo_path;
                    }
                }
                }
            }else{
                alert.error('Invalid Organization URL');
                set_org_key_logo('/resources/images/infurnia_black.svg')
            }
        }catch(err){
            console.log("error in network call")
        }
        }else{
            set_org_key_logo('/resources/images/infurnia_black.svg')
        }
    }

    const design_revert = async () => {
        console.log('Undo Last Action Button working!')
        var action_response = await window.Promisify(window.Module.design_revert());

        window.location.reload();

		//handle_ui_response(ui_response);
		//update_view();
    }

    window.sentry_capture_exception = (error_key, err) => {
        if(!err){
            return;
        }

        err.constructor.name.includes("Error") != true?err=new Error(err):null;
        if(error_key){
            err.name = error_key + " : "+ err.name
            // err.stack = error_key + " : "+ err.stack
        }

        if(window.Module && window.Module.API.sentry_error_prefix){
            err.name = window.Module.API.sentry_error_prefix + err.name;
        }

        console.error(err);
        Sentry.captureException(err);
    }

    const set_page_loader_trigger = (event) => {
        if(event.nativeEvent){
            var custom_data = event.nativeEvent.custom_data;
            var {show, text} = custom_data

            set_page_loader({show, text})
        }
    }

    window.trigger_page_loader = (show, text, callback) => {
        set_page_loader({show, text}, callback)
    }

    const set_3d_loader_trigger = (event) => {
        if(event.nativeEvent){
            var custom_data = event.nativeEvent.custom_data;
            var {show, text} = custom_data
            set_three_d_loader({show, text})
        }
    }

    window.trigger_3d_page_loader = (show, text) => {
        set_three_d_loader({show, text});
    }

    const show_alert_trigger = (event) => {
        if(event.nativeEvent){
            var custom_data = event.nativeEvent.custom_data;
            var {type, message, timeout} = custom_data

            if(type == "success"){
                alert.success(message, {timeout:timeout});
            }else if(type == "info"){
                alert.info(message, {timeout:timeout});
            }else if(type == "error"){
                alert.error(message, {timeout:timeout});
            }
            
        }
    }

    const save_seller_sku = async() => {
        try{
            triggered_by.key = 'saved';
            let seller_url = window.location.href;
            let sku_id = seller_url.split('/').pop();
            window.reload_after = true;
            let arg = {"from_component_editor":{"route": "open_seller_preview", "sku_id": sku_id, "button_text": "Ignore and Save"}}
            let resp = window.Module.open_new_violations_modal(JSON.stringify(arg))
            let resp_json = JSON.parse(resp);
            if(resp_json.no_violations){
                //directly open seller preview
                let resp = await window.Promisify(window.Module.open_seller_preview(sku_id))
                if(window.handle_ui_response)
                    window.handle_ui_response(resp)   
            }
            else if(window.handle_ui_response) window.handle_ui_response(resp);
        } catch(err){
            alert.error('Could not save');
            err.constructor.name.includes("Error") != true?err=new Error(err):null;
            err.name = "Captured error in Save & Refresh (save_seller_sku) in Seller Portal : " + err.name;
            console.error(err);
            window.sentry_capture_exception("",err);
        }
    }

    const [feature_guide, set_feature_guide] = useState([])

    const initFeatureGuides = async() => {
        let resp = await general_fetch({url: '/feature_guide/get_all', body: {filter_by_user_id: true}})
        resp = resp.filter(x => x.status === "Live" && x.portal == "design")
        set_feature_guide(resp)
        return resp;
    }

    const updateFeatureGuides = async(id, status) => {
        let resp = await general_fetch({url: '/feature_guide/update_user_status', body: {id: id, user_status: status}})
        set_feature_guide(resp);
        return resp;
    }

    window.disable_feature_guide = (status) => {
        sessionStorage.setItem("disable_feature_guide", status ? 'true' : 'false')
        forceReRender()
    }
    
    return (<div className="App inf-text-sm DMSans">
        {/* <Feature_Guide joyrideConfigs={feature_guide} /> */}
        <Feature_Guide joyrideConfigs={feature_guide} onFinishSkipCallback={updateFeatureGuides} initCallback={initFeatureGuides} />
        {/* <button style={{display:'none'}} id="sentry_capture_exception_trigger" onClick={capture_error_wrapper}></button> */}
        {/* <button style={{display:'none'}} id="set_page_loader_trigger" onClick={set_page_loader_trigger}></button> */}
        {/* <button style={{display:'none'}} id="set_3d_loader_trigger" onClick={set_3d_loader_trigger}></button> */}
        <button style={{display:'none'}} id="show_alert_trigger" onClick={show_alert_trigger}></button>
        <canvas style={{display:'none'}} id="material_preview_canvas" width="265" height="265"></canvas>

        {three_d_loader && three_d_loader.show?(
            <div className='flex_center DMSans' style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.5)',left:0,top:0,width:'100%',height:'100%',zIndex:1100}}>
                <div className='flex_center px-3 py-2' style={{ background: 'white', borderRadius:'10px', maxWidth: '400px'}}>
                    <img src='/resources/icons/loading_animation.gif' width={'64px'} height={'64px'} className='mr-3'></img>
                    <div className='mr-3' style={{fontSize: '16px', color: '#676878', fontWeight: 500}}>{three_d_loader.text}</div>
                </div>
            </div>
        ):''}

        {page_loader && page_loader.show?(
            <div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.5)',left:0,top:0,width:'100%',height:'100%',zIndex:1100}}>
                {page_loader.text !== 'design_fail'  && page_loader.text !== 'unauthorized_access' && page_loader.text !== 'design_doesnt_exist' && page_loader.text != 'design_out_of_sync'?
                <div className='flex_center DMSans' style={{ position: 'fixed', backgroundColor: 'rgba(20,20,20,0.5)', left: 0, top: 0, width: '100%', height: '100%', zIndex: 1100 }}>
                    <div className='flex_center px-3 py-2' style={{ background: 'white', borderRadius: '10px', maxWidth: '400px' }}>
                        <img src='/resources/icons/loading_animation.gif' width={'64px'} height={'64px'} className='mr-3'></img>
                        <div className='mr-3' style={{ fontSize: '16px', color: '#676878', fontWeight: 500 }}>{page_loader.text}</div>
                    </div>
                </div> :
                (<Card className="bg-secondary shadow border-0" style={{width: "500px",height: "200px",position: "fixed",left: "calc(50vw - 250px)",top: "calc(50vh - 100px)"}}>
                <CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color, fontSize:'16px', display:'flex',justifyContent:'space-between', alignItems:'center', fontWeight:600}} >	
                    {
                        page_loader.text=="design_out_of_sync"?
                            <span>Design version out of sync</span> : 
                            <span>Something went wrong</span> 
                    }
                </CardHeader>
                <CardBody style = {{backgroundColor: COLORS.white, maxHeight:'500px', overflow:'auto', padding:'10px 20px 20px'}}>
                    {page_loader.text=="design_fail"?(<CardText>An error occured while loading your design. We have sent the error log to our technical team.  </CardText>):''}
                    {page_loader.text=="unauthorized_access"?(<CardText>Link sharing has been disabled for current design. Kindly ask the owner of the design to enable it </CardText>):''}
                    {page_loader.text=="design_doesnt_exist"?(<CardText>The Design you have entered doens't exist. Kindly check the design ID in the url </CardText>):''}
                    {page_loader.text=="design_out_of_sync"?(
                        <React.Fragment>
                            <CardText>Your current tab is not displaying the latest version of this design. This may happen if you were working on the same design in a different tab or on another device. Please reload the page to sync with the latest updates on this design branch.</CardText>
                            <Button color = "primary" onClick={() => {window.location.reload()}}> Reload </Button>
                        </React.Fragment>
                    ):''}
                    {/* <CardText><Button onClick={design_revert} disabled={false} style={{height:'30px'}} className='blue_button'>Revert</Button></CardText> */}
                </CardBody>
            </Card>)}
            </div>
        ):''}

        {
            page_loader_outer && page_loader_outer.show ?
                <Loader text={page_loader_outer.text} org_key={get_org_key()} />
            : ''
        }

        {version_outdated && !server_under_maintainence && !is_store_suspended && is_user_active ? (
            (window.location.href.includes('/seller/') || window.location.href.includes('/component/')) ? 
            <div className='DMSans' style={{position:'fixed', zIndex: 1000, width:'480px',right: '16px',top:'152px',backgroundColor:'white',color:COLORS.black,display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px', padding:'36px 24px', boxShadow: '0px 4px 8px 0px #00000040', flexDirection:'column'}}>
                <div className='mb-4' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className='mb-4'>
                        <div>Our software, is not up to date.</div>
                        <div>Refresh to get update.</div>
                    </div>
                    <div style={{color: 'var(--inf-warning-text-color)', textAlign: 'center'}}>
                        <div>Unsaved changes will be lost.</div>
                        <div>Please save your work before refreshing.</div>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', gap: '20px', alignItems: 'center'}}>
                    <Button className='secondary_button_default' onClick={() => {window.location.reload(true)}}>Refresh</Button>
                    <Button className='primary_button_default' onClick={async() => {await save_seller_sku()}}>Save & Refresh</Button>
                </div>
            </div> :
            <div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.5',left:0,top:0,width:'100%',height:'100%',zIndex:10000}}>
                <div  className='DMSans' style={{position:'absolute',width:'480px',left:'calc(50% - 240px)',top:'calc(50% - 90px)',backgroundColor:'white',color:COLORS.black,display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px', padding:'36px 24px', flexDirection:'column'}}>
                    <div className='mb-4'>
                        <div className='mb-4'>
                            <div>{t(`Our software on your browser is not up to date`)} </div>
                            <div>{t(`Refresh to get update.`)}</div>
                        </div>
                        <div style={{color: 'var(--inf-theme-gray-500)'}}>
                            <div>"{t(`Don't worry, your work in progress is saved.`)}"</div>
                        </div>
                    </div>
                    <Button className='primary_button_default' onClick={() => {window.location.reload(true)}}>Refresh</Button>
                </div>
            </div>
        ):('')}
        {user_loaded && !server_under_maintainence && !is_store_suspended && is_user_active && !insufficient_roles/*&&version_checked&&(!version_outdated)*/?(
            <div>
                <Router history={history}>
                    <Switch>
                        <Route exact path={["/:org_key/login/:target?", "/login/:target?"]} render={({ match }) => {
                            const {org_key} = match.params;
                            if(!org_key){
                                return <Redirect to={'/'}/>
                            }else{
                                return <Redirect to={`/${org_key}/`}/>
                            }
                        }} />
                        <Route exact path={['/:org_key/project/:project_id', '/project/:project_id']} render={({ match }) => (<Designs key={match.params.project_id} init={init} logout={logout} init_needed={init_needed} set_init_needed={set_init_needed} org_key={get_org_key()} submit_for_delete_confirm={submit_for_delete_confirm} active_project_id={match.params.project_id} set_page_loader={set_page_loader} Filler={Filler} store_details={store_details} />)} />
                        <Route exact path={["/:org_key/design/:branch_id", "/design/:branch_id", "/:org_key/mes-design-edit/:branch_id", "/mes-design-edit/:branch_id"]} render={({ match }) => (<EditDesign init={init} logout={logout} login_error={login_error} init_needed={init_needed} set_init_needed={set_init_needed} org_key={get_org_key()} submit_for_delete_confirm={submit_for_delete_confirm} wait_for_version_save_queue={wait_for_version_save_queue} is_user_admin={is_user_admin} baking_view={false} design_version_only={false} remote_view={false} seller_specific={false} mes_editor={false} set_page_loader={set_page_loader} active_branch_id={match.params.branch_id} store_details={store_details} />)} />
                        <Route exact path={["/:org_key/share/:branch_id", "/share/:branch_id"]} render={({ match }) => (<EditDesign init={init} logout={logout} login_error={login_error} init_needed={init_needed} set_init_needed={set_init_needed} org_key={get_org_key()} submit_for_delete_confirm={submit_for_delete_confirm} baking_view={false} design_version_only={false} remote_view={true} seller_specific={false} mes_editor={false} set_page_loader={set_page_loader} active_branch_id={match.params.branch_id} store_details={store_details} />)} />
                        <Route exact path={["/:org_key/design-version/:branch_id", "/design-version/:branch_id"]} render={({ match }) => (<EditDesign init={init} login_error={login_error} logout={logout} init_needed={init_needed} set_init_needed={set_init_needed} org_key={get_org_key()} submit_for_delete_confirm={submit_for_delete_confirm} baking_view={false} design_version_only={true} remote_view={true} seller_specific={false} mes_editor={false} set_page_loader={set_page_loader} active_branch_id={match.params.branch_id} store_details={store_details} />)} />
                        <Route exact path={["/:org_key/bake-view/:branch_id", "/bake-view/:branch_id"]} render={({ match }) => (<EditDesign init={init} login_error={login_error} logout={logout} init_needed={init_needed} set_init_needed={set_init_needed} org_key={get_org_key()} submit_for_delete_confirm={submit_for_delete_confirm} baking_view={true} design_version_only={true} remote_view={true} seller_specific={false} mes_editor={false} set_page_loader={set_page_loader} active_branch_id={match.params.branch_id} store_details={store_details} />)} />
                        <Route exact path={["/:org_key/mes-editor/:work_order_id/:component_id", "/mes-editor/:work_order_id/:component_id"]} render={({ match }) => (<EditDesign init={init} login_error={login_error} logout={logout} init_needed={init_needed} set_init_needed={set_init_needed} org_key={get_org_key()} submit_for_delete_confirm={submit_for_delete_confirm} wait_for_version_save_queue={wait_for_version_save_queue} baking_view={false} design_version_only={false} remote_view={false} seller_specific={false} mes_editor={true} set_page_loader={set_page_loader} work_order_id={match.params.work_order_id} component_id={match.params.component_id} store_details={store_details} />)} />
                        <Route exact path={["/:org_key/component/:sku_id", "/component/:sku_id"]} render={({ match }) => (<EditDesign init={init} login_error={login_error} logout={logout} init_needed={init_needed} set_init_needed={set_init_needed} org_key={get_org_key()} submit_for_delete_confirm={submit_for_delete_confirm} baking_view={false} design_version_only={false} remote_view={false} seller_specific={true} mes_editor={false} set_page_loader={set_page_loader} sku_id={match.params.sku_id} store_details={store_details} />)} />
                        <Route exact path={["/:org_key/", "/"]} render={({ match }) => <Home init={init} logout={logout} init_needed={init_needed} set_init_needed={set_init_needed} org_key={get_org_key()} submit_for_delete_confirm={submit_for_delete_confirm} store_details={store_details} set_page_loader={set_page_loader} Filler={Filler} />} />
                        <Route path="/:org_key?/design/:branch_id/:remainder*" render={({ match }) => {
                            const { org_key, branch_id, remainder } = match.params;
                            if (!org_key) {
                                // If org_key is not present
                                return <Redirect to={`/design/${branch_id}`} />;
                            } else {
                                // If org_key, redirect to /{org_key}/design/:branch_id
                                return <Redirect to={`/${org_key}/design/${branch_id}`} />;
                            }
                        }} />
                        <Route path="/:org_key?/mes-design-edit/:branch_id/:remainder*" render={({ match }) => {
                            const { org_key, branch_id, remainder } = match.params;
                            if (!org_key) {
                                // If org_key is not present
                                return <Redirect to={`/mes-design-edit/${branch_id}`} />;
                            } else {
                                // If org_key, redirect to /{org_key}/mes-design-edit/:branch_id
                                return <Redirect to={`/${org_key}/mes-design-edit/${branch_id}`} />;
                            }
                        }} />
                        <Route path="/:org_key?/share/:branch_id/:remainder*" render={({ match }) => {
                            const { org_key, branch_id, remainder } = match.params;
                            if (!org_key) {
                                // If org_key is not present
                                return <Redirect to={`/share/${branch_id}`} />;
                            } else {
                                // If org_key, redirect to /{org_key}/share/:branch_id
                                return <Redirect to={`/${org_key}/share/${branch_id}`} />;
                            }
                        }} />
                        <Route path="/:org_key?/design-version/:branch_id/:remainder*" render={({ match }) => {
                            const { org_key, branch_id, remainder } = match.params;
                            if (!org_key) {
                                // If org_key is not present
                                return <Redirect to={`/design-version/${branch_id}`} />;
                            } else {
                                // If org_key, redirect to /{org_key}/design-version/:branch_id
                                return <Redirect to={`/${org_key}/design-version/${branch_id}`} />;
                            }
                        }} />
                        <Route path="/:org_key?/bake-view/:branch_id/:remainder*" render={({ match }) => {
                            const { org_key, branch_id, remainder } = match.params;
                            if (!org_key) {
                                // If org_key is not present
                                return <Redirect to={`/bake-view/${branch_id}`} />;
                            } else {
                                // If org_key, redirect to /{org_key}/bake-view/:branch_id
                                return <Redirect to={`/${org_key}/bake-view/${branch_id}`} />;
                            }
                        }} />
                        <Route path="/:org_key?/mes-editor/:work_order_component_id/:remainder*" render={({ match }) => {
                            const { org_key, work_order_component_id, remainder } = match.params;
                            if (!org_key) {
                                // If org_key is not present
                                return <Redirect to={`/mes-editor/${work_order_component_id}`} />;
                            } else {
                                // If org_key, redirect to /{org_key}/mes-editor/:work_order_component_id
                                return <Redirect to={`/${org_key}/mes-editor/${work_order_component_id}`} />;
                            }
                        }} />
                        {/* For Backward compatability - Redirecting seller to component */}
                        <Route path="/:org_key?/seller/:sku_id/:remainder*" render={({ match }) => {
                            const { org_key, sku_id, remainder } = match.params;
                            if (!org_key) {
                                // If org_key is not present
                                return <Redirect to={`/component/${sku_id}`} />;
                            } else {
                                // If org_key, redirect to /{org_key}/component/:sku_id
                                return <Redirect to={`/${org_key}/component/${sku_id}`} />;
                            }
                        }} />
                        <Route path="/:org_key?/component/:sku_id/:remainder*" render={({ match }) => {
                            const { org_key, sku_id, remainder } = match.params;
                            if (!org_key) {
                                // If org_key is not present
                                return <Redirect to={`/component/${sku_id}`} />;
                            } else {
                                // If org_key, redirect to /{org_key}/component/:sku_id
                                return <Redirect to={`/${org_key}/component/${sku_id}`} />;
                            }
                        }} />
                        <Route render={() => (<div>{t(`Not Found`)}</div>)} />
                    </Switch>
                </Router>
            </div>
            ):( 
                <React.Fragment>
                    <img alt='Not Available' src="/resources/images/site_image_new.png" style={{position:'fixed',left:0,top:0,width:'100%',height:'100%'}} />
                    {
                        device_isnt_supported?(
                            <Card className="bg-secondary shadow border-0" style={{width: "500px",height: "200px",position: "fixed",left: "calc(50vw - 250px)",top: "calc(50vh - 100px)"}}>
                                <CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color, fontSize:'16px', display:'flex',justifyContent:'center', alignItems:'center', fontWeight:600}} >	
                                    <span>Infurnia isn't supported on your device currently</span>
                                </CardHeader>
                                <CardBody style = {{backgroundColor: COLORS.white, maxHeight:'500px', overflow:'auto', padding:'10px 20px 20px'}}>
                                    We are working on bringing support to more devices and browsers.  <br /><br /> Till then, we suggest using Google Chrome on windows/macOS/linux to get the best experience
                                </CardBody>
                            </Card>
                        )
                        :
                        server_under_maintainence?(
                            <Card className="bg-secondary shadow border-0" style={{width: "500px",height: "130px",position: "fixed",left: "calc(50vw - 250px)",top: "calc(50vh - 100px)"}}>
                                <CardBody style = {{backgroundColor: COLORS.white, maxHeight:'500px', overflow:'auto', padding:'10px 20px 20px'}}>
                                    Our website is currently undergoing maintenance and will be back online at 3 AM (IST). We apologize for any inconvenience caused. Thank you for your patience.
                                </CardBody>
                            </Card>
                        )
                        :
                        is_store_suspended?(
                            <Card className="bg-secondary shadow border-0" style={{width: "500px",height: "130px",position: "fixed",left: "calc(50vw - 250px)",top: "calc(50vh - 100px)"}}>
                                <CardBody className='flex_center' style = {{backgroundColor: COLORS.white, maxHeight:'500px', overflow:'auto', padding:'10px 20px', flexDirection: 'column'}}>
                                    <div className='mb-3'>Your account has been suspended. Please contact admin of {store_details && store_details.store_name ? store_details.store_name : 'your organization'}</div>
                                    <div onClick={logout} style={{color: '#0078ff', cursor:'pointer'}}>{t(`Logout`)}</div>
                                </CardBody>
                            </Card>
                        )
                        :insufficient_roles ?
                            <Card className="shadow border-0 flex_center" style={{ width: "500px", padding: '64px', borderRadius: '12px' , position: "fixed", left: "50vw", top: '50vh', transform: 'translate(-50%, -50%)' }}>
                                <CardBody>
                                    <div className='mb-3'>You are not set as a designer by your admin. Please contact admin of your organization for designer access</div>
                                    <div onClick={() => window.location.href = `${window.Module.API.login_path}/portals`} style={{ color: '#0078ff', cursor: 'pointer' }}>{t(`Check out other portals`)}</div>
                                </CardBody>
                            </Card>
                        :(is_user_active === false)?(
                            <Card className="shadow border-0" style={{width: "500px",height: "130px",position: "fixed",left: "calc(50vw - 250px)",top: "calc(50vh - 100px)"}}>
                                <CardBody className='flex_center' style = {{backgroundColor: COLORS.white, maxHeight:'500px', overflow:'auto', padding:'10px 20px', flexDirection: 'column'}}>
                                    <div className='mb-3'>You are not set as an active user by your admin. Please contact admin of {store_details && store_details.store_name ? store_details.store_name : 'your organization'}</div>
                                    <div onClick={logout} style={{color: '#0078ff', cursor:'pointer'}}>{t(`Logout`)}</div>
                                </CardBody>
                            </Card>
                        )
                        :
                        <Card className="shadow border-0" style={{ width: "500px", height: "130px", position: "fixed", left: "calc(50vw - 250px)", top: "calc(50vh - 100px)" }}>
                            <CardBody className='flex_center' style={{ backgroundColor: COLORS.white, maxHeight: '500px', overflow: 'auto', padding: '10px 20px', flexDirection: 'column' }}>
                                <div className='mb-3'>There was an issue getting your details. Please try logging back in.</div>
                                <div onClick={logout} style={{ color: '#0078ff', cursor: 'pointer' }}>{t(`Logout`)}</div>
                            </CardBody>
                        </Card>
                    }
                </React.Fragment>
            )
        }
    </div>)
    
    
}    

export default App;

//<Route exact path="/home" render={() => (<Home user = {user.user_details} />)}/><Redirect to='/Login'/>